import React, { useState, useEffect } from 'react';
import Details from '../../shared/components/Details/Details';
import './DriverDetails.scss';
import * as Yup from 'yup';
import {
  IFormData,
  IFormSchema,
  IOptions,
  IOptionsData,
} from '../../interfaces/Form-Modal.interface';
import { useTranslation } from 'react-i18next';
import { ICard, ICardItem } from '../../interfaces/Details.interface';
import {
  IAnnualVettingsDataTable,
  IColumnTable,
  IPreloadChecksDataTable,
} from '../../interfaces/Table.interface';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Mock from '../../constants/Mock';
import {
  FetchFiltersAgents,
  FetchFiltersProducts,
  FetchFiltersTransporters,
  FiltersRouteParamsUrl,
  GetAnnualVettingsDataTable,
  GetPreloadChecksDataTable,
  InitFiltersData,
} from '../../services/Helper.service';
import {getDriver } from '../../services/Driver.service';
import { IDriverDetail } from '../../interfaces/Driver.interface';
import Loader from '../../shared/components/Loader/Loader';
import Snackbar from '@mui/material/Snackbar';
import moment from 'moment';
import { useSnackbar } from '../../services/SnackbarUtils';
import FiltersMock from '../../constants/Fitlers';
import { ChecksFilters } from '../../constants/Dashboard';
import { getAnnualVettingsList, getPreloadChecksList } from '../../services/PreloadCheck.service';

interface IDriversProps {
  transporters: IOptions[];
  FiltersTransportersList: IOptionsData;
  FiltersProductsList: IOptionsData;
  FiltersAgentsList: IOptionsData;
}

const DriverDetails = (props: IDriversProps): React.JSX.Element => {
  const { t } = useTranslation('common');
  const [searchValue, setSearchValue] = React.useState('');
  const [preloadChecksListPaginationTotal, setPreloadChecksListPaginationTotal] = useState(0);
  const [annualVettingsListPaginationTotal, setAnnualVettingsListPaginationTotal] = useState(0);
  const [preloadChecksDataTable, setPreloadChecksDataTable] = React.useState<
    IPreloadChecksDataTable[]
  >([]);
  const [annualVettingsDataTable, setAnnualVettingsDataTable] = React.useState<
    IAnnualVettingsDataTable[]
  >([]);
  const { StatusSelectField, TransporterSelectField, ProductSelectField, AgentSelectField } =
    FiltersMock();
  const PreloadCheckFiltPreloadCheckFiltersDataers: IFormData[] = [
    StatusSelectField,
    {
      ...TransporterSelectField,
      optionsData: props.FiltersTransportersList,
      handleFetchMoreData: FetchFiltersTransporters,
    },
    {
      ...ProductSelectField,
      optionsData: props.FiltersProductsList,
      handleFetchMoreData: FetchFiltersProducts,
    },
    {
      ...AgentSelectField,
      optionsData: props.FiltersAgentsList,
      handleFetchMoreData: FetchFiltersAgents,
    },
  ];
  const [PreloadCheckFilters, setPreloadCheckFilters] = useState<IFormData[]>(
    PreloadCheckFiltPreloadCheckFiltersDataers
  );
  const [driverDetails, setDriverDetails] = useState<IDriverDetail>();
  const [isDriverFetched, setIsDriverFetched] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [cardDriverInfo, setCardDriverInfo] = useState<ICardItem[]>([]);
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const { PreloadCheckListColumns, AnnualVettingListColumns } = Mock();
  const formSchema: IFormSchema = Yup.object({
    surname: Yup.string().required(),
    name: Yup.string().required(),
    affiliate: Yup.object().required(),
    transporter: Yup.object().required(),
    id_number: Yup.string().required(),
    licence_number: Yup.string().required(),
    licence_expiration_date: Yup.string().required(),
  });
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(
    searchParams.get('activeTab') !== null &&
      searchParams.get('activeTab') === ChecksFilters.ANNUALVETTINGS
      ? ChecksFilters.ANNUALVETTINGS
      : ChecksFilters.PRELOADCHECKS
  );
  const statusFilters = searchParams
    .get('statusFilters')
    ?.split(',')
    .map((item) => item.trim());
  const transporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());
  const productFilters = searchParams
    .get('productFilters')
    ?.split(',')
    .map((item) => item.trim());
  const agentFilters = searchParams
    .get('agentFilters')
    ?.split(',')
    .map((item) => item.trim());
  const cards: ICard[] = [
    {
      title: t('driver_information'),
      card: cardDriverInfo,
    },
  ];

  const updateFiltersData = (newFilters: IFormData[]): void => {
    setPreloadCheckFilters(newFilters);
    const url = FiltersRouteParamsUrl(location.pathname, newFilters, activeTab);
    navigate(url);
  };

  const handleTableRowClicked = (preloadCheckId: string): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      navigate(`/preload-checks/details/${preloadCheckId}?isAnnualVetting=true&parentTab=drivers`);
    } else {
      navigate(`/preload-checks/details/${preloadCheckId}?parentTab=drivers`);
    }
  };

  /* METHOD EMITTING API CALLS */
  /* Get Driver Data Method */
  const fetchDriver = (): void => {
    setIsRequestInProgress(true);
    getDriver(params.id as string)
      .then(async (driverData) => {
        setIsRequestInProgress(false);
        setDriverDetails(driverData);
        setPreloadChecksListPaginationTotal(0);
        setAnnualVettingsListPaginationTotal(0);
        getCardDriverInfo(driverData);
        setIsDriverFetched(true);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchPreloadChecks = (offset: number): void => {
    const driverIdCard = [driverDetails?.card_id as string];
    if(driverIdCard.length === 0) return;
    setIsRequestInProgress(true);

    getPreloadChecksList(
      10,
      offset,
      searchValue,
      statusFilters,
      null,
      transporterFilters,
      productFilters,
      agentFilters,
      undefined,
      driverIdCard
    )
      .then((preloadCheckData) => {
        setIsRequestInProgress(false);
        setPreloadChecksListPaginationTotal(preloadCheckData.pagination.totalPages);

        // Method That Sets Preload Checks Array Data
        setPreloadChecksDataTable(GetPreloadChecksDataTable(preloadCheckData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchAnnualVettings = (offset: number): void => {
    const driverId = [params.id as string];
    setIsRequestInProgress(true);

    getAnnualVettingsList(
      10,
      offset,
      searchValue,
      statusFilters,
      null,
      transporterFilters,
      productFilters,
      agentFilters,
      undefined,
      driverId
    )
      .then((annualVettingData) => {
        setIsRequestInProgress(false);
        setAnnualVettingsListPaginationTotal(annualVettingData.pagination.totalPages);

        // Method That Sets Annual Vettings Array Data
        setAnnualVettingsDataTable(GetAnnualVettingsDataTable(annualVettingData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const searchSubmit = (value: string): void => {
    setSearchValue(value);
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
  };

  const handleTablePaginationChange = (tablePageIndex: number, isAnnualVetting: boolean): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;

    if (!isAnnualVetting) {
      fetchPreloadChecks(offset);
    } else {
      fetchAnnualVettings(offset);
    }
  };

  useEffect(() => {
    fetchDriver();
  }, []);

  useEffect(() => {
    if (isDriverFetched) {
      SetFiltersData();
      GetChecksData();
    }
  }, [isDriverFetched, activeTab, searchValue, location]);

  const GetChecksData = (): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      fetchAnnualVettings(0);
    } else {
      fetchPreloadChecks(0);
    }
  };

  const SetFiltersData = (): void => {
    const InitFiltersOptions = InitFiltersData(
      PreloadCheckFilters,
      props.FiltersTransportersList,
      props.FiltersProductsList,
      props.FiltersAgentsList,
      statusFilters,
      transporterFilters,
      productFilters,
      agentFilters
    );
    setPreloadCheckFilters(InitFiltersOptions);
  };

  /* Fill Details Card Method */
  const getCardDriverInfo = (driverData: IDriverDetail): void => {
    const cardDriverInfo: ICardItem[] = [
      {
        label: 'name',
        value: `${driverData.name} ${driverData.last_name}`,
        link: `/drivers/details/${driverData.id}`,
      },
      {
        label: 'id_number',
        value: driverData.card_id,
      },
      {
        label: 'affiliate',
        value: driverData.affiliate,
      },
      {
        label: 'driver_licence',
        value: driverData.driver_licence,
      },
      {
        label: 'licence_expirtation_date',
        value:
          driverData.exp_licence === null
            ? '-'
            : moment(driverData.exp_licence).format('YYYY/MM/DD'),
      },
    ];

    setCardDriverInfo(cardDriverInfo);
  };

  const handleTabChange = (tabName: string): void => {
    setActiveTab(tabName);
    const url = FiltersRouteParamsUrl(location.pathname, PreloadCheckFilters, tabName);
    navigate(url);
  };

  return (
    <div>
      {isRequestInProgress && <Loader />}
      {driverDetails !== undefined && (
        <Details
          title={`${driverDetails.name} ${driverDetails.last_name}`}
          subtitle={t('drivers')}
          cards={cards}
          displayHeaderEditBtn={false}
          formSchema={formSchema}
          searchValue={searchValue}
          onSearchSubmit={searchSubmit}
          onSearchClear={searchHandleClear}
          preloadChecksDataTable={preloadChecksDataTable}
          annualVettingsChecksDataTable={annualVettingsDataTable}
          preloadChecksColumnsTable={PreloadCheckListColumns as IColumnTable[]}
          annualVettingsColumnsTable={AnnualVettingListColumns as IColumnTable[]}
          preloadChecksTableTableTotalPages={preloadChecksListPaginationTotal}
          annualVettingsTableTableTotalPages={annualVettingsListPaginationTotal}
          filtersData={PreloadCheckFilters}
          updateFiltersData={updateFiltersData}
          onTableRowClicked={handleTableRowClicked}
          emptyMsgTable={t('no_preload_check_or_anual_vettings_no_data_is_available')}
          closeConfirmModalDescription={t('driver_info_will_be_lost')}
          handleTablePaginationChange={handleTablePaginationChange}
          activeTabName={activeTab}
          handleChecksTabChanged={handleTabChange}
          searchPlaceholder={t('driver_search_placeholder')}
        />
      )}

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default DriverDetails;
