import React from 'react';
import { FieldEnums } from '../enums/Field.enum';
import { IFormData, IOptions } from '../interfaces/Form-Modal.interface';
import {
  IColumnTable
} from '../interfaces/Table.interface';
import { Status, ComplianceLevelConst } from './Status';
import IconStatusApproved from './../assets/icons/icon-status-approved.svg';
import IconStatusRejected from './../assets/icons/icon-status-rejected.svg';
import IconStatusTodo from './../assets/icons/icon-status-todo.svg';
import IconStatusPending from './../assets/icons/icon-status-pending.svg';
import { useTranslation } from 'react-i18next';
import { PreloadCheckQuestionStatusEnums } from '../enums/Status.enum';
import ImageModal from '../shared/components/image-modal/Image-modal';

const Mock = (): Record<string, IFormData[] | IColumnTable[]> => {
  const { t } = useTranslation('common');
  const AgentsModalForm: IFormData[] = [
    {
      componentType: FieldEnums.TEXTFIELD,
      label: t('surname'),
      name: 'surname',
    },
    {
      componentType: FieldEnums.TEXTFIELD,
      label: t('name'),
      name: 'name',
    },
    {
      componentType: FieldEnums.SELECT,
      label: t('country'),
      name: 'country',
      options: [],
      relativeFieldName: 'deport'
    },
    {
      componentType: FieldEnums.SELECT,
      label: t('deport'),
      name: 'deport',
      options: [],
      relativeFieldName: 'country',
      disabled: true
    },
    {
      componentType: FieldEnums.SELECT,
      label: t('role'),
      name: 'role',
      options: [
        {
          label: t('agent'),
          value: 'agent',
        },
        {
          label: t('security_agent'),
          value: 'security_agent',
        },
      ],
    },
    {
      componentType: FieldEnums.SUBTITLE,
      label: t('account_credentials'),
      name: 'account_credentials',
    },
    {
      componentType: FieldEnums.TEXTFIELD,
      label: t('login_id'),
      name: 'login_id',
      disabled: true,
    },
    {
      componentType: FieldEnums.TEXTFIELD,
      label: t('password'),
      name: 'password',
      type: 'password',
    },
  ];

  const QuestionsColumns: IColumnTable[] = [
    {
      accessorKey: 'category',
      header: t('category'),
      maxSize: 20,
    },
    {
      accessorKey: 'requirement',
      header: t('requirement'),
      minSize: 500,
      Cell: (data: any) => {
        const subCategory = data.row.original.subCategory;
        const requirement = subCategory ?? data.row.original.requirement;

        return <span className="requirement-container">{requirement}</span>;
      },
    },
    {
      accessorKey: 'comments',
      header: t('comments'),
      Cell: (data: any) => {
        const isASubCategory = isSubCategory(data);
        if (isASubCategory) {
          return <span></span>;
        }
        const comments = data.row.original.comments;
        return comments !== null && comments !== '' ? (
          <span className="comments-container">{data.row.original.comments}</span>
        ) : (
          <span>-</span>
        );
      },
    },
    {
      accessorKey: 'photo',
      header: t('photo'),
      Cell: (data: any) => {
        const isASubCategory = isSubCategory(data);
        if (isASubCategory) {
          return <span></span>;
        }
        return data.row.original.photo !== null ? (
          <ImageModal imageBase64={data.row.original.photo} />
        ) : (
          <span>-</span>
        );
      },
    },
  ];

  const CheckQuestionsColumns: IColumnTable[] = [
    {
      accessorKey: 'tolerance',
      header: t('tolerance'),
      maxSize: 20,
      Cell: (data: any) => {
        return <span className="tolerance-container">{data.row.original.tolerance}</span>;
      },
    },
    ...QuestionsColumns,
  ];

  const PreloadCheckQuestionsColumns: IColumnTable[] = [
    ...CheckQuestionsColumns,
    {
      accessorKey: 'status',
      header: t('status'),
      Cell: (data: any) => {
        return (
          <div className="status-container">{getQuestionStatusIcon(data.row.original.status)}</div>
        );
      },
    },
  ];

  const AnnualVettingQuestionsColumns: IColumnTable[] = [
    ...QuestionsColumns,
    {
      accessorKey: 'compliance_level',
      header: t('compliance_level'),
      Cell: (data: any) => {
        const isASubCategory = isSubCategory(data);
        if (isASubCategory) {
          return <span></span>;
        }
        return getComplianceLevel(data.row.original.compliance_level);
      },
    },
  ];

  const CheckColumns: IColumnTable[] = [
    {
      accessorKey: 'trailer',
      header: t('trailer'),
    },
    {
      accessorKey: 'product',
      header: t('product'),
      Cell: ({ cell }: { cell: any }) => <div dangerouslySetInnerHTML={{ __html: cell.getValue() }} />,
    },
    {
      accessorKey: 'agent',
      header: t('agent'),
    },
    {
      accessorKey: 'date',
      header: t('date'),
      sortingFn: 'datetime',
    },
    {
      accessorKey: 'status',
      header: t('status'),
      Cell: (data: any) => {
        return <div className="status-container">{getStatusIcon(data.row.original.status)}</div>;
      },
    },
  ];

  const DriversListColumns: IColumnTable[] = [
    {
      accessorKey: 'surname',
      header: t('surname'),
    },
    {
      accessorKey: 'name',
      header: t('name'),
    },
    {
      accessorKey: 'transporter',
      header: t('transporter'),
    },
    {
      accessorKey: 'affiliate',
      header: t('affiliate'),
    },
    {
      accessorKey: 'licenceNumber',
      header: t('licence_number'),
    },
    {
      accessorKey: 'licenceExpirationDate',
      header: t('licence_expirtation_date'),
    },
    {
      accessorKey: 'checksNumber',
      header: t('checks'),
    },
  ];

  const PreloadCheckListColumns: IColumnTable[] = [
    {
      accessorKey: 'orderNumber',
      header: t('order_number'),
      Cell: ({ cell }: { cell: any }) => <div dangerouslySetInnerHTML={{ __html: cell.getValue() }} />,
    },
    {
      accessorKey: 'transporter',
      header: t('transporter'),
    },
    {
      accessorKey: 'truck',
      header: t('truck'),
    },
    ...CheckColumns,
  ];

  const AnnualVettingListColumns: IColumnTable[] = [
    {
      accessorKey: 'truck',
      header: t('truck'),
    },
    {
      accessorKey: 'transporter',
      header: t('transporter'),
    },
    ...CheckColumns,
  ];

  const TransportersContractsModalForm: IFormData[] = [
    AffiliateField,
    {
      componentType: FieldEnums.TEXTFIELD,
      label: 'transporter',
      name: 'transporter'
    },
    {
      componentType: FieldEnums.TEXTFIELD,
      label: 'reference_number',
      name: 'reference_number', 
    },
    {
      componentType: FieldEnums.SELECT,
      label: 'product',
      name: 'product',
      options: [],
      handleSearchLoadOptions: (
        _inputValue: string,
        _params: { affiliate?: string; accessToken?: string },
        _callback: (options: IOptions[]) => void
      ) => {},
    },
    {
      componentType: FieldEnums.DATEPICKER,
      label: 'start_date',
      name: 'start_date',
    },
    {
      componentType: FieldEnums.DATEPICKER,
      label: 'expiration_date',
      name: 'expiration_date',
    },
  ];

  const TrailersModalForm: IFormData[] = [
    { ...AffiliateField, relativeFieldName: 'transporter' },
    {
      componentType: FieldEnums.SELECT,
      label: 'transporter',
      name: 'transporter',
      options: [],
      CustomHandleSearchLoadOptions: (
        _affiliate: string,
        _inputValue: string,
        _callback: (options: IOptions[]) => void
      ) => {},
      disabled: true,
      relativeFieldName: 'affiliate'
    },
    RegistrationNumberField,
    {
      componentType: FieldEnums.SELECT,
      label: 'product',
      name: 'product',
      options: [],
      handleSearchLoadOptions: (
        _inputValue: string,
        _params: { affiliate?: string; accessToken?: string },
        _callback: (options: IOptions[]) => void
      ) => {},
    },
    {
      componentType: FieldEnums.TEXTFIELD,
      label: 'capacity',
      name: 'capacity',
    },
    {
      componentType: FieldEnums.SELECT,
      label: 'unit',
      name: 'unit',
      options: [
        {
          label: 'm3',
          value: 'm3',
        },
        {
          label: 'L',
          value: 'L',
        },
        {
          label: 'Kg',
          value: 'Kg',
        },
      ],
    },
    {
      componentType: FieldEnums.TEXTFIELD,
      label: 'number_compartments',
      name: 'number_compartments',
    },
  ];

  const DriversModalForm: IFormData[] = [
    {
      componentType: FieldEnums.TEXTFIELD,
      label: 'surname',
      name: 'surname',
    },
    {
      componentType: FieldEnums.TEXTFIELD,
      label: 'name',
      name: 'name',
    },
    { ...AffiliateField, relativeFieldName: 'transporter' },
    {
      componentType: FieldEnums.SELECT,
      label: 'transporter',
      name: 'transporter',
      options: [],
      CustomHandleSearchLoadOptions: (
        _affiliate: string,
        _inputValue: string,
        _callback: (options: IOptions[]) => void
      ) => {},
      disabled: true,
      relativeFieldName: 'affiliate'
    },
    {
      componentType: FieldEnums.TEXTFIELD,
      label: 'id_number',
      name: 'id_number',
    },
    {
      componentType: FieldEnums.TEXTFIELD,
      label: 'licence_number',
      name: 'licence_number',
    },
    {
      componentType: FieldEnums.DATEPICKER,
      label: 'licence_expirtation_date',
      name: 'licence_expiration_date',
    },
  ];

  const TrucksModalForm: IFormData[] = [
    { ...AffiliateField, relativeFieldName: 'transporter' },
    {
      componentType: FieldEnums.SELECT,
      label: 'transporter',
      name: 'transporter',
      options: [],
      CustomHandleSearchLoadOptions: (
        _affiliate: string,
        _inputValue: string,
        _callback: (options: IOptions[]) => void
      ) => {},
      disabled: true,
      relativeFieldName: 'affiliate'
    },
    {
      componentType: FieldEnums.TEXTFIELD,
      label: 'truck_number',
      name: 'truck_number',
    },
  ];
  
  const EscalationModalForm: IFormData[] = [
    {
      componentType: FieldEnums.SELECT,
      label: 'manager',
      name: 'manager',
      wide: true,
      options: [],
      handleSearchLoadOptions: (
        _inputValue: string,
        _params: { affiliate?: string; accessToken?: string },
        _callback: (options: IOptions[]) => void
      ) => {},
    },
    {
      componentType: FieldEnums.TEXTFIELD,
      label: 'comment',
      name: 'comment',
      wide: true,
      multiline: true,
    },
  ];

  return {
    PreloadCheckListColumns,
    AnnualVettingListColumns,
    DriversListColumns,
    AgentsModalForm,
    PreloadCheckQuestionsColumns,
    CheckQuestionsColumns,
    AnnualVettingQuestionsColumns,
    TransportersContractsModalForm,
    TrailersModalForm,
    DriversModalForm,
    TrucksModalForm,
    EscalationModalForm,
  };
};

export default Mock;

const AffiliateField: IFormData = {
  componentType: FieldEnums.SELECT,
  label: 'affiliate',
  name: 'affiliate',
};

const RegistrationNumberField: IFormData = {
  componentType: FieldEnums.TEXTFIELD,
  label: 'registration_number',
  name: 'registration_number',
};

const getComplianceLevel = (complianceLevel: string): React.JSX.Element => {
  switch (complianceLevel) {
    case ComplianceLevelConst.LEVEL_1:
      return <span className="compliance-container level-1">1</span>;
    case ComplianceLevelConst.LEVEL_2:
      return <span className="compliance-container level-2">2</span>;
    case ComplianceLevelConst.LEVEL_3:
      return <span className="compliance-container level-3">3</span>;
    default:
      return <span className="compliance-container level-4">4</span>;
  }
};

const getStatusIcon = (status: string): React.JSX.Element => {
  switch (status) {
    case Status.APPROVED:
      return <img src={IconStatusApproved} alt="icon-status" />;
    case Status.REJECTED:
      return <img src={IconStatusRejected} alt="icon-status" />;
    case Status.PENDINGREQUEST:
      return <img src={IconStatusPending} alt="icon-status" />;
    default:
      return <img src={IconStatusTodo} alt="icon-status" />;
  }
};

const getQuestionStatusIcon = (status: PreloadCheckQuestionStatusEnums): React.JSX.Element => {
  switch (status) {
    case PreloadCheckQuestionStatusEnums.ACCEPTABLE:
      return <img src={IconStatusApproved} alt="icon-status" />;
    default:
      return <img src={IconStatusRejected} alt="icon-status" />;
  }
};

const isSubCategory = (cellData: any): boolean => {
  return Boolean(cellData.row.original?.subCategory);
};
