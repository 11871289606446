import React, { useState, useEffect } from 'react';
import Details from '../../shared/components/Details/Details';
import './TrailerDetails.scss';
import * as Yup from 'yup';
import { IFormData, IFormSchema, IOptions, IOptionsData } from '../../interfaces/Form-Modal.interface';
import { useTranslation } from 'react-i18next';
import { ICard, ICardItem } from '../../interfaces/Details.interface';
import {
  IAnnualVettingsDataTable,
  IColumnTable,
  IPreloadChecksDataTable,
} from '../../interfaces/Table.interface';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Mock from '../../constants/Mock';

import {
  FetchFiltersAgents,
  FetchFiltersProducts,
  FetchFiltersTransporters,
  FiltersRouteParamsUrl,
  GetAnnualVettingsDataTable,
  GetPreloadChecksDataTable,
  InitFiltersData,
} from '../../services/Helper.service';
import Loader from '../../shared/components/Loader/Loader';
import { Snackbar } from '@mui/material';
import { getTrailer } from '../../services/Trailer.service';
import { ITrailer } from '../../interfaces/Trailer.interface';
import { useSnackbar } from '../../services/SnackbarUtils';
import FiltersMock from '../../constants/Fitlers';
import { getAnnualVettingsList, getPreloadChecksList } from '../../services/PreloadCheck.service';
import { ChecksFilters } from '../../constants/Dashboard';

interface ITrailersProps {
  transporters: IOptions[];
  products: IOptions[];
  FiltersTransportersList: IOptionsData;
  FiltersProductsList: IOptionsData;
  FiltersAgentsList: IOptionsData;
}

const TrailerDetails = (props: ITrailersProps): JSX.Element => {
  const { t } = useTranslation('common');
  const [searchValue, setSearchValue] = React.useState('');
  const [trailerPreloadChecksListPaginationTotal, setTrailerPreloadChecksListPaginationTotal] =
    useState(0);
  const [annualVettingsListPaginationTotal, setAnnualVettingsPaginationTotal] = useState(0);
  const [trailerPreloadChecksDataTable, setTrailerPreloadChecksDataTable] = React.useState<
    IPreloadChecksDataTable[]
  >([]);
  const [trailerAnnualVettingsDataTable, setTrailerAnnualVettingsDataTable] = React.useState<
    IAnnualVettingsDataTable[]
  >([]);
  const { StatusSelectField, TransporterSelectField, ProductSelectField, AgentSelectField } =
    FiltersMock();
  const PreloadCheckFiltersData: IFormData[] = [
    StatusSelectField,
    {
      ...TransporterSelectField,
      optionsData: props.FiltersTransportersList,
      handleFetchMoreData: FetchFiltersTransporters,
    },
    {
      ...ProductSelectField,
      optionsData: props.FiltersProductsList,
      handleFetchMoreData: FetchFiltersProducts,
    },
    {
      ...AgentSelectField,
      optionsData: props.FiltersAgentsList,
      handleFetchMoreData: FetchFiltersAgents,
    },
  ];
  const [TrailerPreloadCheckFilters, setTrailerPreloadCheckFilters] =
    useState<IFormData[]>(PreloadCheckFiltersData);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [trailerDetails, setTrailerDetails] = useState<ITrailer>();
  const [cardTrailerInfo, setCardTrailerInfo] = useState<ICardItem[]>([]);

  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(
    searchParams.get('activeTab') !== null &&
      searchParams.get('activeTab') === ChecksFilters.ANNUALVETTINGS
      ? ChecksFilters.ANNUALVETTINGS
      : ChecksFilters.PRELOADCHECKS
  );
  const trailerStatusFilters = searchParams
    .get('statusFilters')
    ?.split(',')
    .map((item) => item.trim());
  const trailerTransporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());
  const trailerProductFilters = searchParams
    .get('productFilters')
    ?.split(',')
    .map((item) => item.trim());
  const trailerAgentFilters = searchParams
    .get('agentFilters')
    ?.split(',')
    .map((item) => item.trim());

  const { PreloadCheckListColumns, AnnualVettingListColumns } = Mock();

  const formSchema: IFormSchema = Yup.object({
    affiliate: Yup.object().required(),
    transporter: Yup.object().required(),
    registration_number: Yup.string().required(),
    product: Yup.object().required(),
    capacity: Yup.string().required(),
    unit: Yup.object().required(),
    number_compartments: Yup.string().required(),
  });

  const cards: ICard[] = [
    {
      title: t('main_information'),
      card: cardTrailerInfo,
    },
  ];

  /* METHOD EMITTING API CALLS */
  /* Get Trailer Data Method */
  const fetchTrailer = (): void => {
    setIsRequestInProgress(true);
    getTrailer(params.id as string)
      .then(async (trailerData) => {
        setIsRequestInProgress(false);
        setTrailerDetails(trailerData);
        getCardTrailerInfo(trailerData);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchPreloadChecks = (offset: number): void => {
    const trailerId = [params.id as string];
    setIsRequestInProgress(true);

    getPreloadChecksList(
      10,
      offset,
      searchValue,
      trailerStatusFilters,
      null,
      trailerTransporterFilters,
      trailerProductFilters,
      trailerAgentFilters,
      trailerId
    )
      .then((preloadCheckData) => {
        setIsRequestInProgress(false);
        setTrailerPreloadChecksListPaginationTotal(preloadCheckData.pagination.totalPages);
        // Method That Sets Preload Checks Array Data
        setTrailerPreloadChecksDataTable(GetPreloadChecksDataTable(preloadCheckData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchAnnualVettings = (offset: number): void => {
    const trailerId = [params.id as string];
    setIsRequestInProgress(true);

    getAnnualVettingsList(
      10,
      offset,
      searchValue,
      trailerStatusFilters,
      null,
      trailerTransporterFilters,
      trailerProductFilters,
      trailerAgentFilters,
      trailerId
    )
      .then((annualVettingData) => {
        setIsRequestInProgress(false);
        setAnnualVettingsPaginationTotal(annualVettingData.pagination.totalPages);

        // Method That Sets Annual Vettings Array Data
        setTrailerAnnualVettingsDataTable(GetAnnualVettingsDataTable(annualVettingData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number, isAnnualVetting: boolean): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;

    if (!isAnnualVetting) {
      fetchPreloadChecks(offset);
    } else {
      fetchAnnualVettings(offset);
    }
  };

  useEffect(() => {
    fetchTrailer();
  }, []);

  useEffect(() => {
    SetFiltersData();
    /* GET CHECKS DATA */
    GetChecksData();
  }, [props, location, activeTab, searchValue]);

  const GetChecksData = (): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      fetchAnnualVettings(0);
    } else {
      fetchPreloadChecks(0);
    }
  };

  const SetFiltersData = (): void => {
    const InitFiltersOptions = InitFiltersData(
      TrailerPreloadCheckFilters,
      props.FiltersTransportersList,
      props.FiltersProductsList,
      props.FiltersAgentsList,
      trailerStatusFilters,
      trailerTransporterFilters,
      trailerProductFilters,
      trailerAgentFilters
    );
    setTrailerPreloadCheckFilters(InitFiltersOptions);
  };

  /* Fill Details Card Method */
  const getCardTrailerInfo = (trailerData: ITrailer): void => {
    const cardTrailerInfo: ICardItem[] = [
      {
        label: 'affiliate',
        value: trailerData.affiliate,
      },
      {
        label: 'transporter',
        value: trailerData.transporter === undefined ? '-' : trailerData.transporter,
      },
      {
        label: 'product',
        value: trailerData.product ?? '-',
      },

      {
        label: 'capacity',
        value: trailerData.capacity === null ? '-' : trailerData.capacity,
      },
      {
        label: 'unit',
        value: trailerData.unit === null ? '-' : trailerData.unit,
      },
      {
        label: 'number_compartments',
        value: trailerData.number_compartments === null ? '-' : trailerData.number_compartments,
      },
    ];

    setCardTrailerInfo(cardTrailerInfo);
  };

  const updateTrailersFiltersData = (newFilters: IFormData[]): void => {
    setTrailerPreloadCheckFilters(newFilters);
    const url = FiltersRouteParamsUrl(location.pathname, newFilters, activeTab);
    navigate(url);
  };

  const searchSubmit = (value: string): void => {
    setSearchValue(value);
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
  };

  const trailerRowClicked = (preloadCheckId: string): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      navigate(`/preload-checks/details/${preloadCheckId}?isAnnualVetting=true&parentTab=trailers`);
    } else {
      navigate(`/preload-checks/details/${preloadCheckId}?parentTab=trailers`);
    }
  };

  const handleTabChange = (tabName: string): void => {
    setActiveTab(tabName);
    const url = FiltersRouteParamsUrl(location.pathname, TrailerPreloadCheckFilters, tabName);
    navigate(url);
  };

  return (
    <div>
      {isRequestInProgress && <Loader />}

      {trailerDetails !== undefined && (
        <Details
          title={trailerDetails.registration_number}
          subtitle={t('trailers')}
          cards={cards}
          displayHeaderEditBtn={false}
          formSchema={formSchema}
          searchValue={searchValue}
          onSearchSubmit={searchSubmit}
          onSearchClear={searchHandleClear}
          preloadChecksColumnsTable={PreloadCheckListColumns as IColumnTable[]}
          preloadChecksDataTable={trailerPreloadChecksDataTable}
          annualVettingsChecksDataTable={trailerAnnualVettingsDataTable}
          annualVettingsColumnsTable={AnnualVettingListColumns as IColumnTable[]}
          preloadChecksTableTableTotalPages={trailerPreloadChecksListPaginationTotal}
          annualVettingsTableTableTotalPages={annualVettingsListPaginationTotal}
          filtersData={TrailerPreloadCheckFilters}
          updateFiltersData={updateTrailersFiltersData}
          onTableRowClicked={trailerRowClicked}
          emptyMsgTable={t('no_preload_check_or_anual_vettings_no_data_is_available')}
          closeConfirmModalDescription={t('trailer_info_will_be_lost')}
          handleTablePaginationChange={handleTablePaginationChange}
          activeTabName={activeTab}
          handleChecksTabChanged={handleTabChange}
          searchPlaceholder={t('trailer_search_placeholder')}
        />
      )}

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default TrailerDetails;

