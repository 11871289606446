import axios from 'axios';
import { axiosAuthInstance } from './Auth.service';
import { IManager, IManagerPayload } from '../interfaces/Manager.interface';

// eslint-disable-next-line
const graphGroupManagerUrl = `https://graph.microsoft.com/v1.0/me/memberOf?$select=id,displayName&$search="displayName:DW_TruckVetting_Manager"`
const graphGroupMembersUrl = (id: string): string => `https://graph.microsoft.com/v1.0/groups/${id}/members?$select=id,mail,displayName`

// In-memory cache to store the last access token and managers list
let cache: { accessToken: string | null, managers: IManager[] } | null = null;

/**
 * Searches for managers based on a given search term.
 * 
 * @param search - The search term to filter managers by name. Can be undefined.
 * @param accessToken - The bearer token for authentication. If provided, it will be used to fetch fresh data.
 * @returns A promise that resolves to an array of managers that match the search term.
 */
export const searchManagersList = async (search: string | undefined, accessToken?: string | null): Promise<IManager[]> => {
    // Return filtered cached managers if search term is provided and cache exists
    if(search !== undefined && cache !== null) {
        return cache.managers.filter(manager => manager.name.toLowerCase().includes(search.toLowerCase()));
    }

    // Return cached data if the token matches and cache exists
    if (cache !== null && accessToken === cache.accessToken) {
        return cache.managers;
    }

    // If accessToken is undefined, invalidate cache and return an empty list
    if (accessToken === undefined) {
        cache = null;
        return [];
    }

    // Fetch fresh data and update the cache
    const groupId = await fetchManagerGroup(accessToken);
    if (groupId === undefined) return [];

    const managers = await fetchMembersGroup(accessToken, groupId);
    
    // Update cache with new data
    cache = { accessToken, managers };
    return managers;
};

/**
 * Fetches the manager group's ID from the Graph API.
 * 
 * @param token - The bearer token for authentication.
 * @returns A promise that resolves to the manager group's ID or undefined if not found.
 */
const fetchManagerGroup = async (token: string | null): Promise<string | undefined> => {
    let id: string | undefined;
    if(token === null) return id;

    try {
        const response = await axios.get<{ value: Array<{ id: string }> }>(graphGroupManagerUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
                ConsistencyLevel: 'eventual',
            },
        });

        const items = response.data?.value ?? [];

        // If items are found, get the ID of the first item.
        if (items.length > 0) {
            id = items[0].id;
        }
    } catch (error) {
        console.error('Error fetching manager group:', error);
    }

    return id;
};

/**
 * Fetches the members of a specified group from the Graph API.
 * 
 * @param token - The bearer token for authentication.
 * @param groupId - The ID of the group whose members are to be fetched.
 * @returns A promise that resolves to an array of managers in the group.
 */
const fetchMembersGroup = async (token: string | null, groupId: string): Promise<IManager[]> => {
    let members: IManager[] = [];
    if(token === null) return members;  

    try {
        const response = await axios.get<{ value: any[] }>(graphGroupMembersUrl(groupId), {
            headers: {
                Authorization: `Bearer ${token}`,
                ConsistencyLevel: 'eventual',
            },
        });

        const items = response.data?.value ?? [];

        // Map the fetched items to the IManager format.
        members = items.map((item: any) => ({
            id: item.id,
            name: item.displayName,
            mail: item.mail,
        }));

    } catch (error) {
        console.error('Error fetching group members:', error);
    }

    return members;
};

export const sendEmail = async (payload: IManagerPayload): Promise<void> => {
    const client = await axiosAuthInstance();
    const response = await client.post('/email-server', payload);
    return response.data;
};
