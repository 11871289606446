import { Snackbar } from '@mui/material';
import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IFormData, IOptions } from '../../interfaces/Form-Modal.interface';
import { IColumnTable, ITransportersDataTable } from '../../interfaces/Table.interface';
import { ITransporterContract } from '../../interfaces/TransporterContract.interface';
import { getTransportersContractsList } from '../../services/TransporterContract.service';
import ButtonComponent from '../../shared/components/button/Button';
import Loader from '../../shared/components/Loader/Loader';
import Stats from '../../shared/components/stats/Stats';
import IconExport from './../../assets/icons/icon-export.svg';
import {
  FetchFiltersTransportersContractsTransporters,
  FetchFiltersTransportersContractsTransportersListMethod,
} from '../../services/Helper.service';
import { useSnackbar } from '../../services/SnackbarUtils';
import { IPagination } from '../../interfaces/Pagination.interface';
import { ExportData } from '../../services/Export.service';
import { FieldEnums } from '../../enums/Field.enum';

interface ITransportersProps {
  products: IOptions[];
}

const Transporters = (props: ITransportersProps): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const [transportersListPagination, setTransportersListPagination] = useState<IPagination>();
  const [transportersContractsDataTable, setTransportersContractsDataTable] = useState<
    ITransportersDataTable[]
  >([]);
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>();
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const [nextPageLink, setNextPageLink] = useState<string | null>(null);
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
  const [exportUrl, setExportUrl] = useState<string>();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const transporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());
  const [TransportersFitlers, setTransporterFilters] = useState<IFormData[]>([
    {
      componentType: FieldEnums.CUSTOMSELECT,
      label: 'transporter',
      type: 'text',
      placeholder: 'select_a_transporter',
      name: 'transporter',
      optionsData: undefined,
      handleFetchMoreData: FetchFiltersTransportersContractsTransporters,
    },
  ]);
  const columnsTable: IColumnTable[] = [
    {
      accessorKey: 'transporter',
      header: t('transporter'),
    },
    {
      accessorKey: 'affiliate',
      header: t('affiliate'),
    },
    {
      accessorKey: 'contractRef',
      header: t('contractRef'),
    },
    {
      accessorKey: 'contractStartDate',
      header: t('contractStartDate'),
    },
    {
      accessorKey: 'contractExpDate',
      header: t('contractExpDate'),
    },
  ];

  /* METHOD EMITTING API CALLS */
  const fetchTransportersContractsList = (offset: number, nextLink?: string): void => {
    setIsRequestInProgress(true);

    getTransportersContractsList(10, offset, nextLink, transporterFilters, searchValue)
      .then((transportersData) => {
        setIsRequestInProgress(false);
        setIsFetchingMore(false);

        if (nextLink !== null) {
          setTransportersContractsDataTable((prevData) => [
            ...prevData,
            ...getTransportersContractsDataTable(transportersData.data),
          ]);
        } else {
          setTransportersContractsDataTable(getTransportersContractsDataTable(transportersData.data));
          setTransportersListPagination(transportersData.pagination);
        }

        // Store the next page link for infinite scroll
        setNextPageLink(transportersData.nextLink ?? null);
        setExportUrl(transportersData.exportUrl);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        setIsFetchingMore(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;
    fetchTransportersContractsList(offset);
  };

  const SetFiltersData = async (): Promise<void> => {
    const FiltersTransportersOptionsData = await FetchFiltersTransportersContractsTransportersListMethod('0');
    
    const Filters = TransportersFitlers.map((filter) => {
      filter.optionsData = FiltersTransportersOptionsData;
      filter.optionsData.options = filter.optionsData.options.map((option) => {
        const found = transporterFilters?.find((id: string) => id === option.value);

        return {
          ...option,
          checked: found !== undefined, // Set checked to true if found in ids
        };
      });
      return filter;
    });

    setTransporterFilters(Filters);
  };

  /* OTHER METHODS */
  const getTransportersContractsDataTable = (transportersData: ITransporterContract[]): ITransportersDataTable[] => {
    return transportersData.map((transporter) => {
      return {
        id: transporter.id,
        transporter: transporter.name,
        affiliate: transporter.company_name,
        contractRef: transporter.contract_reference !== null ? transporter.contract_reference : '-',
        contractStartDate:
          transporter?.contract_start_date !== null
            ? moment(transporter.contract_start_date).format('DD/MM/YYYY')
            : '-',
        contractExpDate:
          transporter?.contract_end_date != null
            ? moment(transporter.contract_end_date).format('DD/MM/YYYY')
            : '-',
      };
    });
  };

  /* Search Methods */
  const handleSubmitSearch = (value: string): void => {
    setSearchValue(value);
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
  };

  const transportersRowClicked = (preloadCheckId: string): void => {
    navigate(`/transporters/details/${preloadCheckId}`);
  };

  const updateTransportersFiltersData = (newFilters: IFormData[]): void => {
    setTransporterFilters(newFilters);
    const transporterIds = newFilters
      .find((filter) => filter.name === 'transporter')
      ?.optionsData?.options.filter((option) => option.checked)
      .map((option) => option.value);

    let queyParams = '';

    if (transporterIds !== undefined && transporterIds.length > 0) {
      queyParams += `?transporterFilters=${transporterIds.join(',')}`;
    }

    navigate('/transporters' + queyParams);
  };

  const TransportersExport = (): void => {
    if (exportUrl !== undefined) {
      setIsRequestInProgress(true);

      ExportData(exportUrl)
        .then(() => {
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  const setTransportersContractsHeaderButtons = (): React.JSX.Element => {
    return (
      <>
        {exportUrl !== undefined && (
          <ButtonComponent
            iconUrl={IconExport}
            iconAlt="icon-export"
            label={t('export')}
            color="secondary btn-transparent btn-outlined"
            onClick={TransportersExport}
          />
        )}
      </>
    );
  };

  const filteredTransporters = useMemo(() => {
    if (searchValue === undefined || searchValue.trim() === '' || searchValue === null) return transportersContractsDataTable;

    return transportersContractsDataTable.filter((transporter) =>
      Object.values(transporter).some((value) =>
        typeof value === 'string' && value.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue, transportersContractsDataTable]);

  const handleLoadMore = (): void => {
    if (nextPageLink === null || isFetchingMore) return;
    setIsFetchingMore(true);
    fetchTransportersContractsList(0, nextPageLink);
  };

  useEffect(() => {
    setTransportersContractsDataTable([]);
    setNextPageLink(null);
    void SetFiltersData();
    fetchTransportersContractsList(0);
  }, [props, location]);

  return (
    <div>
      {isRequestInProgress === true && <Loader />}

      <Stats
        searchValue={searchValue}
        onSearchSubmit={handleSubmitSearch}
        onSearchClear={searchHandleClear}
        headerButtons={setTransportersContractsHeaderButtons()}
        dataTable={filteredTransporters}
        columnsTable={columnsTable}
        emptyMsgTable={isRequestInProgress === false ? t('no_transporters_to_display') : ''}
        onTableRowClicked={transportersRowClicked}
        filtersData={TransportersFitlers}
        updateFiltersData={updateTransportersFiltersData}
        handleTablePaginationChange={handleTablePaginationChange}
        tableTotalPages={
          transportersListPagination !== undefined ? transportersListPagination.totalPages : 0
        }
        searchPlaceholder={t('transporter_contract_search_placeholder')}
        nextPageLink={nextPageLink}
        handleLoadMore={handleLoadMore}
      />

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default Transporters;
