import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import en from './assets/i18n/en.json';
import fr from './assets/i18n/fr.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { MsalProvider } from '@azure/msal-react';
import { authStore, msalInstance } from './stores/authStore';

void i18next
  .use(Backend)
  .use(LanguageDetector)
  .init({
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    supportedLngs: ['fr', 'en'],
    fallbackLng: 'en',
    debug: false,
    detection: {
      order: ['queryString', 'cookie'],
      caches: ['cookie'],
      lookupLocalStorage: 'OryxLang',
    },
    resources: {
      en: { common: en },
      fr: { common: fr },
    },
  });

// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-react-samples/b2c-sample/src/index.js

// Initialize MSAL authentication before rendering the app
void authStore.initialize().then(() => {

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </BrowserRouter>
      </I18nextProvider>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
