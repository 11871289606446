import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../button/Button';
import IconLeft from './../../../assets/icons/icon-chevron-left-black.svg';
import IconApproved from './../../../assets/icons/icon-approved-black.svg';
import IconRejected from './../../../assets/icons/icon-close-black.svg';
import IconRightBlack from './../../../assets/icons/icon-chevron-right-black.svg';
import IconRightWhite from './../../../assets/icons/icon-chevron-right.svg';

import './CheckDetails.scss';
import { ICard } from '../../../interfaces/Details.interface';
import { Box } from '@mui/material';
import {
  IAnnualVettingRejectedRequirementDataTable,
  IColumnTable,
  IRejectedRequirementDataTable,
} from '../../../interfaces/Table.interface';
import Table from '../table/Table';
import { PreloadCheckStatus } from '../../../enums/Status.enum';
import IconExport from './../../../assets/icons/icon-export.svg';
import Iconprint from './../../../assets/icons/icon-print.svg';
import { DisplayCards } from '../../../services/Helper.service';

interface DetailsProps {
  title: string;
  pcStatus: PreloadCheckStatus;
  subtitle: string;
  cards: ICard[];
  isCheckDone: boolean;
  rejectedRequirementsDataTable: IRejectedRequirementDataTable[];
  annualVettingRejectedRequirementDataTable: IAnnualVettingRejectedRequirementDataTable[];
  rejectedRequirementsColumnsTable: IColumnTable[];
  annualVettingRejectedRequirementsColumnsTable: IColumnTable[];
  rejectedRequirementsTableTotalPages: number;
  onTableRowClicked: (id: string) => void;
  handleApprovalClicked: () => void;
  handleEscalateClicked: () => void;
  handleRejectionClicked: () => void;
  handleViewEntirePreloadCheckClicked?: () => void;
  handleViewEntireSecurityCheckClicked?: () => void;
  handleExport?: () => void;
  handlePrint?: () => void;
  isAnnualVetting: boolean;
}

const CheckDetails = (props: DetailsProps): JSX.Element => {
  const {
    title,
    pcStatus,
    subtitle,
    cards,
    isCheckDone,
    rejectedRequirementsDataTable,
    annualVettingRejectedRequirementDataTable,
    rejectedRequirementsColumnsTable,
    annualVettingRejectedRequirementsColumnsTable,
    rejectedRequirementsTableTotalPages,
    onTableRowClicked,
    handleApprovalClicked,
    handleEscalateClicked,
    handleRejectionClicked,
    handleViewEntirePreloadCheckClicked,
    handleViewEntireSecurityCheckClicked,
    handleExport,
    handlePrint,
    isAnnualVetting,
  } = props;
  const { t } = useTranslation('common');

  const redirectToPrevPage = (): void => {
    window.history.back();
  };

  const displayAnnualVettingsStatusBadges = (): React.JSX.Element => {
    if (pcStatus === PreloadCheckStatus.APPROVED || pcStatus === PreloadCheckStatus.REJECTED) {
      return HeaderRighComponentsToDisplayWhenPCIsApprovedOrRejected();
    }
    return <div className={`badge ${pcStatus}`}>{t(pcStatus)}</div>;
  };

  const displayStatusBadgeOrBtn = (): React.JSX.Element => {
    if (pcStatus === PreloadCheckStatus.PENDINGREQUEST) {
      return (
        <div className="status-buttons">
          <ButtonComponent
            iconUrl={IconRightWhite}
            iconAlt="icon-chevron-right-white"
            label={t('escalate')}
            color="primary"
            onClick={handleEscalateClicked}
          />

          <ButtonComponent
            iconUrl={IconRejected}
            iconAlt="icon-cross"
            label={t('reject')}
            classes="box-shadow--inactive"
            color="inactive"
            onClick={handleRejectionClicked}
          />
          {/* Display Approved Button When No Rejected Requirement With Tolerance 0  */}
          {rejectedRequirementsDataTable.length === 0 ||
            (rejectedRequirementsDataTable.find((element) => element.tolerance === '0') ===
              undefined && (
              <ButtonComponent
                iconUrl={IconApproved}
                iconAlt="icon-check"
                label={t('approve')}
                classes="box-shadow--active"
                color="active"
                onClick={handleApprovalClicked}
              />
            ))}
        </div>
      );
    } else {
      if (pcStatus === PreloadCheckStatus.APPROVED || pcStatus === PreloadCheckStatus.REJECTED) {
        return HeaderRighComponentsToDisplayWhenPCIsApprovedOrRejected()
      }
      return <div className={`badge ${pcStatus}`}>{t(pcStatus)}</div>;
    }
  };

  const HeaderRighComponentsToDisplayWhenPCIsApprovedOrRejected = (): React.JSX.Element => {
    return (
      <>
        <div className={`badge ${pcStatus}`}>{t(pcStatus)}</div>
        {handleExport !== undefined && 
          <ButtonComponent
            iconUrl={IconExport}
            iconAlt="icon-export"
            label={t('export')}
            color="secondary btn-transparent btn-outlined"
            onClick={handleExport}
          />
        }
        {handlePrint !== undefined &&
          <ButtonComponent
            iconUrl={Iconprint}
            iconAlt="icon-print"
            label={t('print')}
            color="secondary btn-transparent btn-outlined"
            onClick={handlePrint}
          />
        }
      </>
    )
  }

  const getViewEntirePreloadCheckBtnLabel = (): string => {
    return isAnnualVetting ? t('view_entire_annual_vetting') : t('view_entire_preload_check');
  };

  return (
    <div className="oryx-check-details">
      <div className="oryx-check-details__header">
        <div className="header-container">
          <div className="left-side">
            <ButtonComponent
              classes="no-padding btn-transparent"
              iconOnly={true}
              iconRound={false}
              iconUrl={IconLeft}
              iconAlt="icon-chevron-left"
              onClick={redirectToPrevPage}
            />
            <div className="description">
              <h1>{title}</h1>
              <p className="subtitle">{subtitle}</p>
            </div>
          </div>
          <div className="right-side">
            {isAnnualVetting ? displayAnnualVettingsStatusBadges() : displayStatusBadgeOrBtn()}
          </div>
        </div>
      </div>

      <div className="oryx-check-details__body">
        {cards !== undefined && cards.length > 0 && DisplayCards(cards, t)}
        <Box className="rejected-requirements" sx={{ width: '100%' }}>
          {isCheckDone && (
            <Box className="rejected-requirements__header">
              <h4 className="rejected-requirements__title">{t('rejected_requirements')}</h4>
              <div className="right-side">
                {handleViewEntirePreloadCheckClicked !== undefined && (
                  <ButtonComponent
                    iconUrl={IconRightBlack}
                    iconAlt="icon-chevron-right-black"
                    label={getViewEntirePreloadCheckBtnLabel()}
                    color={`${
                      !isAnnualVetting && pcStatus === PreloadCheckStatus.APPROVED
                        ? 'secondary btn-transparent btn-outlined'
                        : 'primary'
                    }`}
                    onClick={handleViewEntirePreloadCheckClicked}
                  />
                )}

                {!isAnnualVetting &&
                  pcStatus === PreloadCheckStatus.APPROVED &&
                  handleViewEntireSecurityCheckClicked !== undefined && (
                    <ButtonComponent
                      iconUrl={IconRightWhite}
                      iconAlt="icon-chevron-right-white"
                      label={t('view_entire_security_check')}
                      color="primary"
                      onClick={handleViewEntireSecurityCheckClicked}
                    />
                  )}
              </div>
            </Box>
          )}
          {!isCheckDone && (
            <p className="empty-table-message">
              {isAnnualVetting
                ? t('annual_vetting_has_not_been_done_yet')
                : t('preload_check_has_not_been_done_yet')}
            </p>
          )}
          {isCheckDone && (
            <div
              className={`rejected-requirements__table ${
                rejectedRequirementsDataTable.length === 0 ? 'empty-table' : ''
              }`}
            >
              <Table
                data={
                  isAnnualVetting
                    ? annualVettingRejectedRequirementDataTable
                    : rejectedRequirementsDataTable
                }
                columns={
                  isAnnualVetting
                    ? annualVettingRejectedRequirementsColumnsTable
                    : rejectedRequirementsColumnsTable
                }
                emptyMsg={t('no_rejected_requirements')}
                onRowClicked={onTableRowClicked}
                totalPages={rejectedRequirementsTableTotalPages}
              />
            </div>
          )}
        </Box>
      </div>
    </div>
  );
};

export default CheckDetails;
