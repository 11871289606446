import { Chip, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { IDataTable } from '../../../interfaces/Table.interface';
import ButtonComponent from '../button/Button';
import Table from '../table/Table';
import IconSearch from './../../../assets/icons/icon-search.svg';
import IconClear from './../../../assets/icons/icon-search-clear.svg';
import IconFilter from './../../../assets/icons/icon-filter.svg';
import './Stats.scss';
import FilterModal from '../filter-modal/Filter-Modal';
import { IFormData, IOptions } from '../../../interfaces/Form-Modal.interface';
import IconLeft from './../../../assets/icons/icon-chevron-left-black.svg';
import { FieldEnums } from '../../../enums/Field.enum';
import moment from 'moment';

interface StatsProps {
  title?: string;
  subtitle?: string;
  displayHeaderLeftSideBlock?: boolean;
  searchValue: string;
  headerButtons?: React.JSX.Element;
  onSearchSubmit: (newSearchValue: string) => void;
  onSearchClear: () => void;
  dataTable: IDataTable[];
  columnsTable: any[];
  emptyMsgTable: string;
  onTableRowClicked: (id: string) => void;
  filtersData: IFormData[];
  updateFiltersData: (filters: IFormData[]) => void;
  handleTablePaginationChange?: (paginationIndex: number) => void;
  tableTotalPages: number;
  searchPlaceholder?: string;
  nextPageLink?: string | null;
  handleLoadMore?: () => void;
}

const Stats = ({
  title,
  subtitle,
  displayHeaderLeftSideBlock,
  searchValue,
  onSearchSubmit,
  onSearchClear,
  dataTable,
  columnsTable,
  emptyMsgTable,
  onTableRowClicked,
  filtersData,
  updateFiltersData,
  headerButtons,
  handleTablePaginationChange,
  tableTotalPages,
  searchPlaceholder,
  nextPageLink,
  handleLoadMore
}: StatsProps): JSX.Element => {
  const [searchValueSource, setSearchValueSource] = useState(searchValue);
  const [openModal, setOpenModal] = useState(false);
  const [Filters, setFilters] = useState(filtersData);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const searchStatsHandleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValueSource(event.target.value);
  };

  const searchStatsHandleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    onSearchSubmit(searchValueSource);
  };

  const searchStatsHandleClick = (): void => {
    onSearchSubmit(searchValueSource);
  };

  const searchStatsHandleClear = (): void => {
    setSearchValueSource('');
    onSearchClear();

    if (inputRef !== null && inputRef.current instanceof HTMLInputElement) {
      // Keep Focus On The Input
      inputRef.current?.focus();
    }
  };

  const filterStatsHandleClick = (): void => {
    setOpenModal(true);
  };

  const filterClose = (): void => {
    setOpenModal(false);
  };

  const statsCheckedFilters = (): IOptions[] => {
    const checkedOptions: IOptions[] = [];
    filtersData
      .filter((item) => item.name !== 'status')
      .forEach((item) => {
        if (item.componentType === FieldEnums.CUSTOMSELECT) {
          // Use filter to get options with checked set to true
          item.optionsData?.options.forEach((option) => {
            if (option.checked === true) {
              checkedOptions.push(option);
            }
          });
        } else {
          if (item.value !== undefined) {
            const dateFormated = moment(item.value).format('YYYY/MM/DD');
            checkedOptions.push({label: dateFormated, value: dateFormated})
          }
        }
      });

    return checkedOptions;
  };

  const handleFilterDelete = (option: IOptions): void => {
    const updatedFilters = Filters.map((filterData) => {
      if (filterData.componentType === FieldEnums.DATEPICKER) {
        filterData.value = undefined;        
      } else {
        filterData.optionsData?.options.forEach((optionData) => {
          if (optionData.value === option.value) {
            optionData.checked = false;
          }
        });
      }

      return filterData;
    });

    setFilters(updatedFilters);
    if (updateFiltersData != null) {
      updateFiltersData(updatedFilters);
    }
  };

  const redirectToPrevPage = (): void => {
    window.history.back();
  };

  useEffect(() => {
    setFilters(filtersData);
  }, [filtersData, updateFiltersData]);

  return (
    <div className="oryx-stats">
      <div className="oryx-stats__header">
        <div className="header-container">
          <div className="left-side">
            {displayHeaderLeftSideBlock === true && (
              <>
                <ButtonComponent
                  classes="no-padding btn-transparent"
                  iconOnly={true}
                  iconRound={false}
                  iconUrl={IconLeft}
                  iconAlt="icon-chevron-left"
                  onClick={redirectToPrevPage}
                />
                <div className="description">
                  <h1>{title}</h1>
                  <p className="subtitle">{subtitle}</p>
                </div>
              </>
            )}
          </div>
          <div className="right-side">
            <ButtonComponent
              classes="no-padding"
              iconOnly={true}
              iconRound={false}
              iconUrl={IconFilter}
              iconAlt="icon-filter"
              onClick={filterStatsHandleClick}
            />

            <form onSubmit={searchStatsHandleSubmit}>
              <TextField
                inputRef={inputRef}
                value={searchValueSource}
                className="search-input"
                size="small"
                placeholder={searchPlaceholder}
                onChange={searchStatsHandleChange}
                InputProps={{
                  endAdornment: (
                    <div className="search-input__end">
                      {searchValueSource.length > 0 && (
                        <ButtonComponent
                          classes="no-padding"
                          iconOnly={true}
                          iconRound={true}
                          iconUrl={IconClear}
                          iconAlt="icon-search"
                          onClick={searchStatsHandleClear}
                        />
                      )}

                      <ButtonComponent
                        classes="no-padding"
                        iconOnly={true}
                        iconRound={true}
                        iconUrl={IconSearch}
                        iconAlt="icon-search"
                        onClick={searchStatsHandleClick}
                      />
                    </div>
                  ),
                }}
              />
            </form>
            {/* Display Header Buttons */}
            {headerButtons}
          </div>
        </div>
        <div className="filters-container">
          {statsCheckedFilters().length > 0 &&
            statsCheckedFilters().map((option) => (
              <Chip
                className="filter-chip"
                key={option.value}
                label={option.label}
                variant="outlined"
                onDelete={() => {
                  handleFilterDelete(option);
                }}
              />
            ))}
        </div>
      </div>

      <div className={`oryx-stats__body ${dataTable.length === 0 ? 'empty-table' : ''}`}>
        <Table
          data={dataTable}
          columns={columnsTable}
          emptyMsg={emptyMsgTable}
          onRowClicked={onTableRowClicked}
          handlePaginationChange={handleTablePaginationChange}
          totalPages={tableTotalPages}
          enableStickyHeader={true}
          handleLoadMore={handleLoadMore}
          nextPageLink={nextPageLink}
        />
      </div>

      {/* Filter Modal */}
      <FilterModal
        openModal={openModal}
        onClose={filterClose}
        filtersData={filtersData}
        updateFiltersData={updateFiltersData}
      />
    </div>
  );
};

export default Stats;
