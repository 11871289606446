import React, { useState, useEffect } from 'react';
import Details from '../../shared/components/Details/Details';
import './TransporterDetails.scss';
import * as Yup from 'yup';
import {
  IFormData,
  IFormSchema,
  IOptions,
  IOptionsData,
} from '../../interfaces/Form-Modal.interface';
import { useTranslation } from 'react-i18next';
import { ICard, ICardItem } from '../../interfaces/Details.interface';
import {
  IAnnualVettingsDataTable,
  IColumnTable,
  IPreloadChecksDataTable,
} from '../../interfaces/Table.interface';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Mock from '../../constants/Mock';
import {
  FetchFiltersAgents,
  FetchFiltersProducts,
  FiltersRouteParamsUrl,
  GetAnnualVettingsDataTable,
  GetPreloadChecksDataTable,
  InitFiltersData,
} from '../../services/Helper.service';
import Loader from '../../shared/components/Loader/Loader';
import { Snackbar } from '@mui/material';
import {
  ITransporterContract,
} from '../../interfaces/TransporterContract.interface';
import {
  getTransporterContract,
} from '../../services/TransporterContract.service';
import moment from 'moment';
import { useSnackbar } from '../../services/SnackbarUtils';
import FiltersMock from '../../constants/Fitlers';
import { ChecksFilters } from '../../constants/Checks';
import { getAnnualVettingsList, getPreloadChecksList } from '../../services/PreloadCheck.service';

interface ITansportersProps {
  FiltersTransportersList: IOptionsData;
  FiltersProductsList: IOptionsData;
  FiltersAgentsList: IOptionsData;
  products: IOptions[];
}

const TransporterDetails = (props: ITansportersProps): JSX.Element => {
  const { t } = useTranslation('common');
  const [searchValue, setSearchValue] = React.useState('');
  const [
    transporterPreloadChecksListPaginationTotal,
    setTransporterPreloadChecksListPaginationTotal,
  ] = useState(0);
  const [
    transporterAnnualVettingsListPaginationTotal,
    setTransporterAnnualVettingsPaginationTotal,
  ] = useState(0);
  const [preloadChecksDataTable, setPreloadChecksDataTable] = React.useState<
    IPreloadChecksDataTable[]
  >([]);
  const [annualVettingsDataTable, setAnnualVettingsDataTable] = React.useState<
    IAnnualVettingsDataTable[]
  >([]);
  const { StatusSelectField, ProductSelectField, AgentSelectField } = FiltersMock();
  const PreloadCheckFiltersData: IFormData[] = [
    StatusSelectField,
    {
      ...ProductSelectField,
      optionsData: props.FiltersProductsList,
      handleFetchMoreData: FetchFiltersProducts,
    },
    {
      ...AgentSelectField,
      optionsData: props.FiltersAgentsList,
      handleFetchMoreData: FetchFiltersAgents,
    },
  ];
  const [PreloadCheckFilters, setPreloadCheckFilters] =
    useState<IFormData[]>(PreloadCheckFiltersData);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [transporterDetails, setTransporterDetailsDetails] = useState<ITransporterContract>();
  const [cardTransporterInfo, setCardTransporterInfo] = useState<ICardItem[]>([]);
  const [cardContractInfo, setCardContractInfo] = useState<ICardItem[]>([]);
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(
    searchParams.get('activeTab') !== null &&
      searchParams.get('activeTab') === ChecksFilters.ANNUALVETTINGS
      ? ChecksFilters.ANNUALVETTINGS
      : ChecksFilters.PRELOADCHECKS
  );
  const transporterStatusFilters = searchParams
    .get('statusFilters')
    ?.split(',')
    .map((item) => item.trim());
  const transporterProductFilters = searchParams
    .get('productFilters')
    ?.split(',')
    .map((item) => item.trim());
  const transporterAgentFilters = searchParams
    .get('agentFilters')
    ?.split(',')
    .map((item) => item.trim());

  const { PreloadCheckListColumns, AnnualVettingListColumns } = Mock();

  const formSchema: IFormSchema = Yup.object({
    affiliate: Yup.object().required(),
    reference_number: Yup.string().required(),
    transporter: Yup.string().required(),
    product: Yup.object().required(),
    start_date: Yup.string().required(),
    expiration_date: Yup.string().required(),
  });

  const cards: ICard[] = [
    {
      title: t('transporter_information'),
      card: cardTransporterInfo,
    },
    {
      title: t('contract_information'),
      card: cardContractInfo,
    },
  ];

  /* METHOD EMITTING API CALLS */
  /* Get Transport Data Method */
  const fetchTransporterContract = (): void => {
    setIsRequestInProgress(true);
    getTransporterContract(params.id as string)
      .then((transporterData) => {
        setIsRequestInProgress(false);
        setTransporterDetailsDetails(transporterData);

        getCardTransporterInfo(transporterData);
        getCardContractInfo(transporterData);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchPreloadChecks = (offset: number): void => {
    const transporterId = [params.id as string];
    setIsRequestInProgress(true);

    getPreloadChecksList(
      10,
      offset,
      searchValue,
      transporterStatusFilters,
      null,
      transporterId,
      transporterProductFilters,
      transporterAgentFilters
    )
      .then((preloadCheckData) => {
        setIsRequestInProgress(false);
        setTransporterPreloadChecksListPaginationTotal(preloadCheckData.pagination.totalPages);

        // Method That Sets Preload Checks Array Data
        setPreloadChecksDataTable(GetPreloadChecksDataTable(preloadCheckData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchAnnualVettings = (offset: number): void => {
    const transporterId = [params.id as string];
    setIsRequestInProgress(true);

    getAnnualVettingsList(
      10,
      offset,
      searchValue,
      transporterStatusFilters,
      null,
      transporterId,
      transporterProductFilters,
      transporterAgentFilters
    )
      .then((annualVettingData) => {
        setIsRequestInProgress(false);
        setTransporterAnnualVettingsPaginationTotal(annualVettingData.pagination.totalPages);

        // Method That Sets Annual Vettings Array Data
        setAnnualVettingsDataTable(GetAnnualVettingsDataTable(annualVettingData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number, isAnnualVetting: boolean): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;

    if (!isAnnualVetting) {
      fetchPreloadChecks(offset);
    } else {
      fetchAnnualVettings(offset);
    }
  };

  useEffect(() => {
    fetchTransporterContract();
  }, []);

  useEffect(() => {
    SetFiltersData();
    /* GET CHECKS DATA */
    GetChecksData();
  }, [props, location, activeTab, searchValue]);


  const GetChecksData = (): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      fetchAnnualVettings(0);
    } else {
      fetchPreloadChecks(0);
    }
  };

  const SetFiltersData = (): void => {
    const InitFiltersOptions = InitFiltersData(
      PreloadCheckFilters,
      undefined,
      props.FiltersProductsList,
      props.FiltersAgentsList,
      transporterStatusFilters,
      undefined,
      transporterProductFilters,
      transporterAgentFilters
    );
    setPreloadCheckFilters(InitFiltersOptions);
  };

  /* Fill Details Card Method */
  const getCardTransporterInfo = (transporterData: ITransporterContract): void => {
    const cardTransporterInfo: ICardItem[] = [
      {
        label: 'affiliate',
        value: transporterData.company_name,
      },
      {
        label: 'transporter',
        value: transporterData.name,
      },
      {
        label: 'product',
        value: transporterData.product !== null ? transporterData.product?.description : '-',
      },
    ];

    setCardTransporterInfo(cardTransporterInfo);
  };

  const getCardContractInfo = (transporterData: ITransporterContract): void => {
    const cardContractInfo: ICardItem[] = [
      {
        label: 'reference_number',
        value: transporterData.contract_reference !== null ? transporterData.contract_reference : '-',
      },
      {
        label: 'start_date',
        value: (transporterData.contract_start_date != null)  ? moment(transporterData.contract_start_date).format('DD/MM/YYYY') : '-',
      },
      {
        label: 'expiration_date',
        value: (transporterData.contract_end_date != null) ? moment(transporterData.contract_end_date).format('DD/MM/YYYY') : '-',
      },
    ];

    setCardContractInfo(cardContractInfo);
  };

  const updatetransportersContractsFiltersData = (newFilters: IFormData[]): void => {
    setPreloadCheckFilters(newFilters);
    const url = FiltersRouteParamsUrl(location.pathname, newFilters, activeTab);
    navigate(url);
  };

  const searchSubmit = (value: string): void => {
    setSearchValue(value);
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
  };

  const transporterContractRowClicked = (preloadCheckId: string): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      navigate(
        `/preload-checks/details/${preloadCheckId}?isAnnualVetting=true&parentTab=transporters`
      );
    } else {
      navigate(`/preload-checks/details/${preloadCheckId}?parentTab=transporters`);
    }
  };

  const handleTabChange = (tabName: string): void => {
    setActiveTab(tabName);
    const url = FiltersRouteParamsUrl(location.pathname, PreloadCheckFilters, tabName);
    navigate(url);
  };

  return (
    <div>
      {isRequestInProgress && <Loader />}

      {transporterDetails !== undefined && (
        <Details
          title={transporterDetails.name}
          subtitle={t('transporters')}
          cards={cards}
          displayHeaderEditBtn={false}
          formSchema={formSchema}
          searchValue={searchValue}
          onSearchSubmit={searchSubmit}
          onSearchClear={searchHandleClear}
          preloadChecksDataTable={preloadChecksDataTable}
          annualVettingsChecksDataTable={annualVettingsDataTable}
          preloadChecksColumnsTable={PreloadCheckListColumns as IColumnTable[]}
          annualVettingsColumnsTable={AnnualVettingListColumns as IColumnTable[]}
          preloadChecksTableTableTotalPages={transporterPreloadChecksListPaginationTotal}
          annualVettingsTableTableTotalPages={transporterAnnualVettingsListPaginationTotal}
          filtersData={PreloadCheckFilters}
          updateFiltersData={updatetransportersContractsFiltersData}
          onTableRowClicked={transporterContractRowClicked}
          emptyMsgTable={t('no_preload_check_or_anual_vettings_no_data_is_available')}
          closeConfirmModalDescription={t('transporter_info_will_be_lost')}
          handleTablePaginationChange={handleTablePaginationChange}
          activeTabName={activeTab}
          handleChecksTabChanged={handleTabChange}
          searchPlaceholder={t('transporter_contract_search_placeholder')}
        />
      )}

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default TransporterDetails;
