import { Snackbar } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IFormData, IOptions } from '../../interfaces/Form-Modal.interface';
import { IColumnTable, ITrailersDataTable } from '../../interfaces/Table.interface';
import { ITrailer } from '../../interfaces/Trailer.interface';
import { getTrailerList } from '../../services/Trailer.service';
import Loader from '../../shared/components/Loader/Loader';
import Stats from '../../shared/components/stats/Stats';
import ButtonComponent from '../../shared/components/button/Button';
import IconExport from './../../assets/icons/icon-export.svg';
import {
  FetchFiltersTrailersProducts,
  FetchFiltersTrailersProductsListMethod,
  FetchFiltersTrailersTransporters,
  FetchFiltersTrailersTransportersListMethod,
  FiltersRouteParamsUrl,
} from '../../services/Helper.service';
import { useSnackbar } from '../../services/SnackbarUtils';
import { IPagination } from '../../interfaces/Pagination.interface';
import { ExportData } from '../../services/Export.service';
import { FieldEnums } from '../../enums/Field.enum';

interface ITrailersProps {
  transporters: IOptions[];
  products: IOptions[];
}

const Trailers = (props: ITrailersProps): JSX.Element => {
  const [trailersListPagination, setTrailersListPagination] = useState<IPagination>();
  const [trailersDataTable, setTrailersDataTable] = useState<ITrailersDataTable[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [nextPageLink, setNextPageLink] = useState<string | null>(null);
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>();
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const [exportUrl, setExportUrl] = useState<string>();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const columnsTable: IColumnTable[] = [
    {
      accessorKey: 'transporter',
      header: t('transporter'),
    },
    {
      accessorKey: 'affiliate',
      header: t('affiliate'),
    },
    {
      accessorKey: 'registration_number',
      header: t('registration_number'),
    },
    {
      accessorKey: 'product',
      header: t('product'),
    },
  ];
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const transporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());
  const productFilters = searchParams
    .get('productFilters')
    ?.split(',')
    .map((item) => item.trim());
  const [TrailersFitlers, setTrailersFitlers] = useState<IFormData[]>([
    {
      componentType: FieldEnums.CUSTOMSELECT,
      label: 'transporter',
      type: 'text',
      placeholder: 'select_a_transporter',
      name: 'transporter',
      optionsData: undefined,
      handleFetchMoreData: FetchFiltersTrailersTransporters,
    },
    {
      componentType: FieldEnums.CUSTOMSELECT,
      label: 'product',
      type: 'text',
      placeholder: 'select_a_product',
      name: 'product',
      optionsData: undefined,
      handleFetchMoreData: FetchFiltersTrailersProducts,
    },
  ]);

  /* METHOD EMITTING API CALLS */
  const fetchTrailersList = (offset: number, nextLink?: string): void => {
    setIsRequestInProgress(true);

    getTrailerList(10, offset, nextLink, transporterFilters, productFilters, searchValue)
      .then(async (trailerData) => {
        setIsRequestInProgress(false);
        setIsFetchingMore(false);

        if (nextLink !== null) {
          setTrailersDataTable((prevData) => [
            ...prevData,
            ...getTrailersDataTable(trailerData.data),
          ]);
        } else {
          setTrailersDataTable(getTrailersDataTable(trailerData.data));
          setTrailersListPagination(trailerData.pagination);
        }

        // Store the next page link for infinite scroll
        setNextPageLink(trailerData.nextLink ?? null);
        setExportUrl(trailerData.exportUrl);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        setIsFetchingMore(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;
    fetchTrailersList(offset);
  };

  const updateTrailersFiltersData = (newFilters: IFormData[]): void => {
    setTrailersFitlers(newFilters);
    const url = FiltersRouteParamsUrl(
      location.pathname,
      newFilters,
      undefined,
    );

    navigate(url);
  };

  /* Export Method */
  const TrailersExport = (): any => {
    if (exportUrl !== undefined) {
      setIsRequestInProgress(true);

      ExportData(exportUrl)
        .then(() => {
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  const SetFiltersData = async (): Promise<void> => {
    const FiltersTransportersOptionsData = await FetchFiltersTrailersTransportersListMethod('0');
    const FiltersProductsOptionsData = await FetchFiltersTrailersProductsListMethod('0');
    
    const Filters = TrailersFitlers.map((filter) => {
      if (filter.name === 'transporter') {
        filter.optionsData = FiltersTransportersOptionsData;
        filter.optionsData.options = filter.optionsData.options.map((option) => {
          const found = transporterFilters?.find((id: string) => id === option.value);

          return {
            ...option,
            checked: found !== undefined, // Set checked to true if found in ids
          };
        });
      } else {
        filter.optionsData = FiltersProductsOptionsData
        filter.optionsData.options = filter.optionsData.options.map((option) => {
          const found = productFilters?.find((id: string) => id === option.value);

          return {
            ...option,
            checked: found !== undefined, // Set checked to true if found in ids
          };
        });
      }
      return filter;
    });

    setTrailersFitlers(Filters);
  };

  /* OTHER METHODS */
  const getTrailersDataTable = (trailersData: ITrailer[]): ITrailersDataTable[] => {
    return trailersData.map((trailer) => {
      return {
        id: trailer.id,
        transporter: trailer.transporter ?? '-',
        affiliate: trailer.affiliate,
        registration_number: trailer.registration_number.trim().length > 0 ? trailer.registration_number : '-',
        product: trailer.product ?? '-',
      };
    });
  };

  /* Search Methods */
  const handleSubmitSearch = (value: string): void => {
    setSearchValue(value);
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
  };

  /* Redirection To Trailers Details */
  const trailersRowClicked = (trailerId: string): void => {
    navigate(`/trailers/details/${trailerId}`);
  };

  /* Method That Displays Buttons On Header's page */
  const setTrailersHeaderButtons = (): React.JSX.Element => {
    return (
      <>
        {exportUrl !== undefined && (
          <ButtonComponent
            iconUrl={IconExport}
            iconAlt="icon-export"
            label={t('export')}
            color="secondary btn-transparent btn-outlined"
            onClick={TrailersExport}
          />
        )}
      </>
    );
  };

  const filteredTrailers = useMemo(() => {
    if (searchValue === undefined || searchValue.trim() === '' || searchValue === null) return trailersDataTable;

    return trailersDataTable.filter((trailer) =>
      Object.values(trailer).some((value) =>
        typeof value === 'string' && value.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue, trailersDataTable]);

  const handleLoadMore = (): void => {
    if (nextPageLink === null || isFetchingMore) return;
    setIsFetchingMore(true);
    fetchTrailersList(0, nextPageLink);
  };

  useEffect(() => {
    setTrailersDataTable([]);
    setNextPageLink(null);
    void SetFiltersData();
    fetchTrailersList(0);
  }, [props, location]);

  return (
    <div>
      {isRequestInProgress === true && <Loader />}

      <Stats
        searchValue={searchValue}
        onSearchSubmit={handleSubmitSearch}
        onSearchClear={searchHandleClear}
        headerButtons={setTrailersHeaderButtons()}
        dataTable={filteredTrailers}
        columnsTable={columnsTable}
        emptyMsgTable={isRequestInProgress === false ? t('no_trailers_to_display') : ''}
        onTableRowClicked={trailersRowClicked}
        filtersData={TrailersFitlers}
        updateFiltersData={updateTrailersFiltersData}
        handleTablePaginationChange={handleTablePaginationChange}
        tableTotalPages={
          trailersListPagination !== undefined ? trailersListPagination.totalPages : 0
        }
        searchPlaceholder={t('trailer_search_placeholder')}
        nextPageLink={nextPageLink}
        handleLoadMore={handleLoadMore}
      />

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default Trailers;
