import React, { useState, useEffect } from 'react';
import Details from '../../shared/components/Details/Details';
import './TruckDetails.scss';
import * as Yup from 'yup';
import { IFormData, IFormSchema, IOptions, IOptionsData } from '../../interfaces/Form-Modal.interface';
import { useTranslation } from 'react-i18next';
import { ICard, ICardItem } from '../../interfaces/Details.interface';
import { IAnnualVettingsDataTable,  IColumnTable,  IPreloadChecksDataTable } from '../../interfaces/Table.interface';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Mock from '../../constants/Mock';
import {
  FetchFiltersAgents,
  FetchFiltersProducts,
  FetchFiltersTransporters,
  FiltersRouteParamsUrl,
  GetAnnualVettingsDataTable,
  GetPreloadChecksDataTable,
  InitFiltersData
} from '../../services/Helper.service';
import { getTruck } from '../../services/Truck.service';
import { ITruckDetail } from '../../interfaces/Truck.interface';
import Loader from '../../shared/components/Loader/Loader';
import { Snackbar } from '@mui/material';
import { useSnackbar } from '../../services/SnackbarUtils';
import FiltersMock from '../../constants/Fitlers';
import { ChecksFilters } from '../../constants/Dashboard';
import { getAnnualVettingsList, getPreloadChecksList } from '../../services/PreloadCheck.service';

interface ITrucksProps {
  transporters: IOptions[];
  FiltersTransportersList: IOptionsData;
  FiltersProductsList: IOptionsData;
  FiltersAgentsList: IOptionsData;
}

const TruckDetails = (props: ITrucksProps): JSX.Element => {
  const { t } = useTranslation('common');
  const [searchValue, setSearchValue] = React.useState('');
  const [truckPreloadChecksListPaginationTotal, setTruckPreloadChecksListPaginationTotal] =
    useState(0);
  const [truckannualVettingsListPaginationTotal, setTruckAnnualVettingsPaginationTotal] =
    useState(0);
  const [preloadChecksDataTable, setPreloadChecksDataTable] = React.useState<
    IPreloadChecksDataTable[]
  >([]);
  const [annualVettingsDataTable, setAnnualVettingsDataTable] = React.useState<
    IAnnualVettingsDataTable[]
  >([]);
  const { StatusSelectField, TransporterSelectField, ProductSelectField, AgentSelectField } =
    FiltersMock();
  const PreloadCheckFiltersData: IFormData[] = [
    StatusSelectField,
    {
      ...TransporterSelectField,
      optionsData: props.FiltersTransportersList,
      handleFetchMoreData: FetchFiltersTransporters,
    },
    {
      ...ProductSelectField,
      optionsData: props.FiltersProductsList,
      handleFetchMoreData: FetchFiltersProducts,
    },
    {
      ...AgentSelectField,
      optionsData: props.FiltersAgentsList,
      handleFetchMoreData: FetchFiltersAgents,
    },
  ];
  const [PreloadCheckFilters, setPreloadCheckFilters] =
    useState<IFormData[]>(PreloadCheckFiltersData);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [truckDetails, setTruckDetails] = useState<ITruckDetail>();
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const [cardTruckInfo, setCardTruckInfo] = useState<ICardItem[]>([]);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(
    searchParams.get('activeTab') !== null &&
      searchParams.get('activeTab') === ChecksFilters.ANNUALVETTINGS
      ? ChecksFilters.ANNUALVETTINGS
      : ChecksFilters.PRELOADCHECKS
  );
  const truckStatusFilters = searchParams
    .get('statusFilters')
    ?.split(',')
    .map((item) => item.trim());
  const truckTransporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());
  const truckProductFilters = searchParams
    .get('productFilters')
    ?.split(',')
    .map((item) => item.trim());
  const truckAgentFilters = searchParams
    .get('agentFilters')
    ?.split(',')
    .map((item) => item.trim());

  const formSchema: IFormSchema = Yup.object({
    truck_number: Yup.string().required(),
    transporter: Yup.object().required(),
  });

  const { PreloadCheckListColumns, AnnualVettingListColumns } = Mock();

  const cards: ICard[] = [
    {
      title: t('main_information'),
      card: cardTruckInfo,
    },
  ];

  /* METHOD EMITTING API CALLS */
  /* Get Truck Data Method */
  const fetchTruck = (): void => {
    setIsRequestInProgress(true);
    getTruck(params.id as string)
      .then(async (truckData) => {
        setIsRequestInProgress(false);
        setTruckDetails(truckData);
        setTruckPreloadChecksListPaginationTotal(0);
        setTruckAnnualVettingsPaginationTotal(0);
        getCardTruckInfo(truckData);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchPreloadChecks = (offset: number): void => {
    const truckId = [params.id as string];
    setIsRequestInProgress(true);

    getPreloadChecksList(
      10,
      offset,
      searchValue,
      truckStatusFilters,
      null,
      truckTransporterFilters,
      truckProductFilters,
      truckAgentFilters,
      undefined,
      undefined,
      truckId
    )
      .then((preloadCheckData) => {
        setIsRequestInProgress(false);
        setTruckPreloadChecksListPaginationTotal(preloadCheckData.pagination.totalPages);

        // Method That Sets Preload Checks Array Data
        setPreloadChecksDataTable(GetPreloadChecksDataTable(preloadCheckData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchAnnualVettings = (offset: number): void => {
    const truckId = [params.id as string];
    setIsRequestInProgress(true);

    getAnnualVettingsList(
      10,
      offset,
      searchValue,
      truckStatusFilters,
      null,
      truckTransporterFilters,
      truckProductFilters,
      truckAgentFilters,
      undefined,
      undefined,
      truckId
    )
      .then((annualVettingData) => {
        setIsRequestInProgress(false);
        setTruckAnnualVettingsPaginationTotal(annualVettingData.pagination.totalPages);

        // Method That Sets Annual Vettings Array Data
        setAnnualVettingsDataTable(GetAnnualVettingsDataTable(annualVettingData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number, isAnnualVetting: boolean): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;

    if (!isAnnualVetting) {
      fetchPreloadChecks(offset);
    } else {
      fetchAnnualVettings(offset);
    }
  };

  useEffect(() => {
    fetchTruck();
  }, []);

  useEffect(() => {
    SetFiltersData();
    /* GET CHECKS DATA */
    GetChecksData();
  }, [props, location, activeTab, searchValue]);

  const GetChecksData = (): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      fetchAnnualVettings(0);
    } else {
      fetchPreloadChecks(0);
    }
  };

  const SetFiltersData = (): void => {
    const InitFiltersOptions = InitFiltersData(
      PreloadCheckFilters,
      props.FiltersTransportersList,
      props.FiltersProductsList,
      props.FiltersAgentsList,
      truckStatusFilters,
      truckTransporterFilters,
      truckProductFilters,
      truckAgentFilters
    );
    setPreloadCheckFilters(InitFiltersOptions);
  };

  /* Fill Details Card Method */
  const getCardTruckInfo = (truckData: ITruckDetail): void => {
    const cardTruckInfo: ICardItem[] = [
      {
        label: 'truck_number',
        value: truckData.truck_number,
      },
      {
        label: 'transporter',
        value: truckData.transporter,
      },
      {
        label: 'affiliate',
        value: truckData.affiliate,
      },
    ];

    setCardTruckInfo(cardTruckInfo);
  };

  const searchSubmit = (value: string): void => {
    setSearchValue(value);
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
  };

  const truckRowClicked = (preloadCheckId: string): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      navigate(`/preload-checks/details/${preloadCheckId}?isAnnualVetting=true&parentTab=trucks`);
    } else {
      navigate(`/preload-checks/details/${preloadCheckId}?parentTab=trucks`);
    }
  };

  const updatePreloadChecksFiltersData = (newFilters: IFormData[]): void => {
    setPreloadCheckFilters(newFilters);
    const url = FiltersRouteParamsUrl(location.pathname, newFilters, activeTab);
    navigate(url);
  };

  const handleTabChange = (tabName: string): void => {
    setActiveTab(tabName);
    const url = FiltersRouteParamsUrl(location.pathname, PreloadCheckFilters, tabName);
    navigate(url);
  };

  return (
    <div>
      {isRequestInProgress && <Loader />}

      {truckDetails !== undefined && (
        <Details
          title={truckDetails.truck_number}
          subtitle={t('trucks')}
          cards={cards}
          displayHeaderEditBtn={false}
          formSchema={formSchema}
          searchValue={searchValue}
          onSearchSubmit={searchSubmit}
          onSearchClear={searchHandleClear}
          preloadChecksDataTable={preloadChecksDataTable}
          annualVettingsChecksDataTable={annualVettingsDataTable}
          preloadChecksColumnsTable={PreloadCheckListColumns as IColumnTable[]}
          annualVettingsColumnsTable={AnnualVettingListColumns as IColumnTable[]}
          preloadChecksTableTableTotalPages={truckPreloadChecksListPaginationTotal}
          annualVettingsTableTableTotalPages={truckannualVettingsListPaginationTotal}
          filtersData={PreloadCheckFilters}
          updateFiltersData={updatePreloadChecksFiltersData}
          onTableRowClicked={truckRowClicked}
          emptyMsgTable={t('no_preload_check_or_anual_vettings_no_data_is_available')}
          closeConfirmModalDescription={t('truck_info_will_be_lost')}
          handleTablePaginationChange={handleTablePaginationChange}
          activeTabName={activeTab}
          handleChecksTabChanged={handleTabChange}
          searchPlaceholder={t('truck_search_placeholder')}
        />
      )}

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default TruckDetails;
