import React, { useEffect, useState } from 'react';
import { Snackbar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Mock from '../../constants/Mock';
import { IAgent, IAgentCreatePayload } from '../../interfaces/Agent.interface';
import { IFormData, IFormSchema, IInitialValues, IOptions } from '../../interfaces/Form-Modal.interface';
import { IAgentsDataTable, IColumnTable } from '../../interfaces/Table.interface';
import AddEditModal from '../../shared/components/add-edit-modal/Add-Edit-Modal';
import Loader from '../../shared/components/Loader/Loader';
import Stats from '../../shared/components/stats/Stats';
import { createAgent, getAgentsList } from '../../services/Agent.service';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../shared/components/button/Button';
import IconExport from './../../assets/icons/icon-export.svg';
import IconCross from './../../assets/icons/icon-cross.svg';
import * as Yup from 'yup';
import { useSnackbar } from '../../services/SnackbarUtils';
import { IPagination } from '../../interfaces/Pagination.interface';
import { ExportData } from '../../services/Export.service';
import { FieldEnums } from '../../enums/Field.enum';
import { getUserDeports } from '../../services/User.service';

interface IAgentsProps {
  CountriesOptions: IOptions[];
}

const formSchema: IFormSchema = Yup.object({
  surname: Yup.string().required(),
  name: Yup.string().required(),
  country: Yup.object().required(),
  deport: Yup.object().required(),
  role: Yup.object().required(),
  password: Yup.string().required(),
});

const formInitialValues: IInitialValues = {
  surname: '',
  name: '',
  country: '',
  deport: '',
  role: '',
  login_id: '',
  password: '',
};

const Agents = (props: IAgentsProps): JSX.Element => {
  const [agentsListPagination, setAgentsListPagination] = useState<IPagination>();
  const [agentsDataTable, setAgentsDataTable] = useState<IAgentsDataTable[]>([]);
  const [agentsSearchValue, setAgentsSearchValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>();
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const { AgentsModalForm } = Mock();
  const [AgentForm, setAgentForm] = useState<IFormData[]>(
    JSON.parse(JSON.stringify(AgentsModalForm))
  );
  const { t } = useTranslation('common');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const roleFilters = searchParams
    .get('roleFilters')
    ?.split(',')
    .map((item) => item.trim());
  const deportFilters = searchParams
    .get('deportFilters')
    ?.split(',')
    .map((item) => item.trim());
    const countryFilters = searchParams
    .get('countryFilters')
    ?.split(',')
    .map((item) => item.trim());
  const AgentsFiltersMock = [
    {
      componentType: FieldEnums.CUSTOMSELECT,
      label: 'role',
      type: 'text',
      placeholder: 'select_a_role',
      name: 'role',
      optionsData: {
        options: [
          {
            label: t('all'),
            value: 'all',
          },
          {
            label: t('agent'),
            value: 'agent',
          },
          {
            label: t('security_agent'),
            value: 'security_agent',
          },
        ],
      },
    },
    {
      componentType: FieldEnums.CUSTOMSELECT,
      label: 'deport',
      type: 'text',
      placeholder: 'select_a_deport',
      name: 'deport',
      optionsData: {
        options: [],
      },
    },
    {
      componentType: FieldEnums.CUSTOMSELECT,
      label: 'country',
      type: 'text',
      placeholder: 'select_a_country',
      name: 'country',
      optionsData: {
        options: props.CountriesOptions
      },
    },
  ];

  const [AgentsFitlers, setAgentsFitlers] = useState<IFormData[]>([...AgentsFiltersMock]);
  const [exportUrl, setExportUrl] = useState<string>();
  const navigate = useNavigate();
  const columnsTable: IColumnTable[] = [
    {
      accessorKey: 'surname',
      header: t('surname'),
    },
    {
      accessorKey: 'name',
      header: t('name'),
    },
    {
      accessorKey: 'country',
      header: t('country'),
    },
    {
      accessorKey: 'deport',
      header: t('deport'),
    },
    {
      accessorKey: 'role',
      header: t('role'),
    },
    {
      accessorKey: 'situation',
      header: t('situation'),
    },
    {
      accessorKey: 'average_time',
      header: t('average_time'),
    },
    {
      accessorKey: 'status',
      header: t('status'),
    },
  ];

  /* METHOD EMITTING API CALLS */
  const fetchAgentsList = (offset: number): void => {
    setIsRequestInProgress(true);
    getAgentsList(offset, roleFilters, deportFilters, countryFilters, agentsSearchValue)
      .then((agentsList) => {
        setIsRequestInProgress(false);
        setAgentsListPagination(agentsList.pagination);
        // Method That Sets Agents Array Data
        getAgentsDataTable(agentsList.data);
        setExportUrl(agentsList.exportUrl);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleCreateAgent = (formData: IFormSchema): void => {
    setIsRequestInProgress(true);
    const agentPayload: IAgentCreatePayload = {
      name: formData.name,
      last_name: formData.surname,
      country: formData.country.value,
      deport: formData.deport.value,
      role: formData.role.value,
      number_id: formData.login_id,
      pwd: formData.password,
    };

    createAgent(agentPayload)
      .then(() => {
        setIsRequestInProgress(false);
        setOpenModal(false);
        showSnackbar(t('agent_has_been_created_successfully'), 'success');
        // Get Agents List After Creating New Agent To Fetch All The List With The New Created Agent
        fetchAgentsList(0);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;
    fetchAgentsList(offset);
  };

  /* Export Method */
  const AgentsExport = (): any => {
    if (exportUrl !== undefined) {
      setIsRequestInProgress(true);

      ExportData(exportUrl)
        .then(() => {
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  const GetCountryDeportListOptions = async (country: string): Promise<IOptions[]> => {
    const deportList = await getUserDeports([country]);
    return deportList.map(deport => {
      return { value: deport, label: deport };
    });
  };

  useEffect(() => {
    void SetFiltersData();
  }, [t]);

  useEffect(() => {
    void SetFiltersData();
    fetchAgentsList(0);
    void SetModalForm();
  }, [props, location, agentsSearchValue]);

  // This Method Is Trigered When Country Select Change It's Value, So We Can Enable The Deport Field
  // And Pass The Right Options For It Depending On Country Value + Pass Relative Field Value To Use It On LoadOptions On Select Field Component
  const handleCountryChange = async (option: IOptions): Promise<void> => {
    const formData = AgentForm;
    // Find the "deport" field in the Form array
    const deportFieldIndex = formData.findIndex((field) => field.name === 'deport');
    if (deportFieldIndex !== -1) {
      // Make deport Field Enabled After Selecting Country Value
      formData[deportFieldIndex].disabled = false;
      // Get Country Value
      const countryValue = option.value;
      // Fill Options Of Deport Dropdown By Method GetCountryDeportListOptions
      formData[deportFieldIndex].options = await GetCountryDeportListOptions(countryValue);
      // Passing Country Value To Use It On Select Field Component To Load Options By API And Send Country Value On API
      formData[deportFieldIndex].relativeFieldValue = countryValue;
      formData[deportFieldIndex].value = undefined;
    }
  }

  const SetModalForm = async (): Promise<void> => {
    const formData = AgentForm;
    
    // Find the "country" field in the Form array
    const countryFieldIndex = formData.findIndex((field) => field.name === 'country');
    if (countryFieldIndex !== -1) {
      // Fill The options By the first default options values
      formData[countryFieldIndex].options = props.CountriesOptions;

      // Add Method To Handle Country Change
      formData[countryFieldIndex].handSelectChange = handleCountryChange;
    }

    setAgentForm(formData);
  };

  const SetFiltersData = async (): Promise<void> => {
    const allUserDeports = await getUserDeports();
    const agentsFiltersData = [...AgentsFiltersMock].map((filter) => {
      if (filter.name === 'deport') {
        filter.optionsData = {
          options: allUserDeports.map((deport) => {
            const found = deportFilters?.find((id: string) => id === deport);

            return { label: deport, value: deport, checked: found !== undefined };
          }),
        };
      } else if (filter.name === 'role') {
        if (filter.optionsData !== undefined) {
          filter.optionsData.options = filter?.optionsData?.options?.map((option) => {
            const found = roleFilters?.find((id: string) => id === option.value);

            return {
              ...option,
              checked: found !== undefined, // Set checked to true if found in ids
            };
          });
        }
      } else {
          filter.optionsData.options = props.CountriesOptions.map((option) => {
          const found = countryFilters?.find((id: string) => id === option.value);

          return {
            ...option,
            checked: found !== undefined, // Set checked to true if found in ids
          };
        });
      }

      return filter;
    });

    setAgentsFitlers(agentsFiltersData);
  };

  /* OTHER METHODS */
  const getAgentsDataTable = (agentsData: IAgent[]): void => {
    const agentsDataTable: IAgentsDataTable[] = agentsData.map((agent) => {
      return {
        id: agent.id,
        surname: agent.last_name,
        name: agent.name,
        country: agent.country,
        deport: agent.deport,
        situation: agent.is_external ? (t('agent_extern') as string) : (t('agent_intern') as string),
        role: t(agent.role),
        average_time: agent.preload_check_average_time?.length > 0 ? agent.preload_check_average_time : '-',
        status: agent.status,
      };
    });

    setAgentsDataTable(agentsDataTable);
  };

  /* Search Methods */
  const handleSubmitSearch = (value: string): void => {
    setAgentsSearchValue(value);
  };

  const searchHandleClear = (): void => {
    setAgentsSearchValue('');
  };

  /* Modal Open Close Methods */
  const openAddAgentModal = (): void => {
    setOpenModal(true);
  };

  const modalClose = (): void => {
    setOpenModal(false);
  };

  const agentsRowClicked = (preloadCheckId: string): void => {
    navigate(`/agents/details/${preloadCheckId}`);
  };

  const updateAgentsFiltersData = (newFilters: IFormData[]): void => {
    setAgentsFitlers(newFilters);

    const roleIds = newFilters
      .find((filter) => filter.name === 'role')
      ?.optionsData?.options.filter((option) => option.checked)
      .map((option) => option.value);

    const deportIds = newFilters
      .find((filter) => filter.name === 'deport')
      ?.optionsData?.options.filter((option) => option.checked)
      .map((option) => option.value);

    const countryIds = newFilters
      .find((filter) => filter.name === 'country')
      ?.optionsData?.options.filter((option) => option.checked)
      .map((option) => option.value);

    let queyParams = '';

    if (roleIds !== undefined && roleIds.length > 0) {
      queyParams += `${queyParams.length > 0 ? '&' : '?'}roleFilters=${roleIds.join(',')}`;
    }

    if (deportIds !== undefined && deportIds.length > 0) {
      queyParams += `${queyParams.length > 0 ? '&' : '?'}deportFilters=${deportIds.join(',')}`;
    }

    if (countryIds !== undefined && countryIds.length > 0) {
      queyParams += `${queyParams.length > 0 ? '&' : '?'}countryFilters=${countryIds.join(',')}`;
    }

    navigate('/agents' + queyParams);
  };

  /* Method That Displays Buttons On Header's page */
  const setAgentsHeaderButtons = (): React.JSX.Element => {
    return (
      <>
        {exportUrl !== undefined && (
          <ButtonComponent
            iconUrl={IconExport}
            iconAlt="icon-export"
            label={t('export')}
            color="secondary btn-transparent btn-outlined"
            onClick={AgentsExport}
          />
        )}

        <ButtonComponent
          iconUrl={IconCross}
          iconAlt="icon-cross"
          label={t('add_agent')}
          color="primary"
          onClick={openAddAgentModal}
        />
      </>
    );
  };

  return (
    <div>
      {isRequestInProgress === true && <Loader />}

      <Stats
        searchValue={agentsSearchValue}
        onSearchSubmit={handleSubmitSearch}
        onSearchClear={searchHandleClear}
        headerButtons={setAgentsHeaderButtons()}
        dataTable={agentsDataTable}
        columnsTable={columnsTable}
        emptyMsgTable={isRequestInProgress === false ? t('no_agents_to_display') : ''}
        onTableRowClicked={agentsRowClicked}
        filtersData={AgentsFitlers}
        updateFiltersData={updateAgentsFiltersData}
        handleTablePaginationChange={handleTablePaginationChange}
        tableTotalPages={agentsListPagination !== undefined ? agentsListPagination.totalPages : 0}
        searchPlaceholder={t('agent_search_placeholder')}
      />

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />

      {/* Add Modal */}
      <AddEditModal
        modalTitle={t('add_an_agent')}
        modalForm={AgentForm}
        formSchema={formSchema}
        formInitialValues={formInitialValues}
        openModal={openModal}
        onClose={modalClose}
        handleSubmit={handleCreateAgent}
        closeConfirmationModalTitle={t('are_you_sure')}
        closeConfirmationModalDescription={t('agent_info_will_be_lost')}
      />
    </div>
  );
};

export default Agents;
