import { IDriverData, IDriverDetail } from '../interfaces/Driver.interface';
import { extractAndEncode } from '../utils/string';
import { axiosAuthInstance } from './Auth.service';

// ct=false Query Param Means Not Filtering By Country
export const getDriversList = async (
  limit: number,
  offset: number,
  nextLink?: string,
  driverIds?: string[],
  searchQuery?: string,
): Promise<IDriverData> => {
  const client = await axiosAuthInstance();
  let queryParams = '';

  driverIds?.forEach((filter) => {
    queryParams += `&transporterIds[]=${filter}`;
  });

  if (searchQuery !== undefined && searchQuery.length > 0) {
    queryParams += `&search=${searchQuery}`;
  }

  if (nextLink !== undefined) {
    queryParams += `&nextLink=${extractAndEncode(nextLink)}`;
  }
  
  const response = await client.get(`/driver/list?limit=${limit}&offset=${offset ?? 0}${queryParams}`);

  return {
    ...response.data,
    nextLink: response.data.pagination?.nextLink,
  };
};

export const getDriver = async (id: string): Promise<IDriverDetail> => {
  const client = await axiosAuthInstance();
  const response = await client.get(`/driver/${id}`);
  return response.data;
};