import { IAgent } from './Agent.interface';

export interface IUser extends IAgent {
  preload_checks: [];
}

export interface IEditUserPayload {
  deport: string;
}

export interface IUserSSOPayload {
  name: string;
  last_name: string;
  email: string;
  deport: string;
  role: string;
  number_id: string;
  external_id: string;
}

export interface ITokens {
  accessToken: string;
  refreshToken: string;
}

export enum UserRole {
  Manager = 'manager',
  SecurityAgent = 'security_agent',
  Agent = 'agent',
}