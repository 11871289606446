import React, { useState, useEffect } from 'react';
import Details from '../../shared/components/Details/Details';
import './AgentDetails.scss';
import * as Yup from 'yup';
import {
  IFormData,
  IFormSchema,
  IInitialValues,
  IOptions,
  IOptionsData,
} from '../../interfaces/Form-Modal.interface';
import { useTranslation } from 'react-i18next';
import { ICard, ICardItem } from '../../interfaces/Details.interface';
import {
  IAnnualVettingsDataTable,
  IColumnTable,
  IPreloadChecksDataTable,
} from '../../interfaces/Table.interface';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Mock from '../../constants/Mock';

import {
  FetchFiltersProducts,
  FetchFiltersTransporters,
  FiltersRouteParamsUrl,
  GetAnnualVettingsDataTable,
  GetPreloadChecksDataTable,
  InitFiltersData,
} from '../../services/Helper.service';
import Loader from '../../shared/components/Loader/Loader';
import { Snackbar } from '@mui/material';
import {
  IAgentDetail,
  IAgentEditPayload,
  IAgentEditStatusPayload,
} from '../../interfaces/Agent.interface';
import { editAgent, editAgentStatus, getAgent } from '../../services/Agent.service';
import IconClose from './../../assets/icons/icon-close-black.svg';
import IconAgent from './../../assets/icons/icon-agent-white.svg';
import { useSnackbar } from '../../services/SnackbarUtils';
import { ChecksFilters } from '../../constants/Dashboard';
import FiltersMock from '../../constants/Fitlers';
import { getAnnualVettingsList, getPreloadChecksList } from '../../services/PreloadCheck.service';
import { AgentStatus } from '../../constants/Status';
import { getUserDeports } from '../../services/User.service';

const formSchema: IFormSchema = Yup.object({
  surname: Yup.string().required(),
  name: Yup.string().required(),
  country: Yup.object().required(),
  deport: Yup.object().required(),
  role: Yup.object().required(),
});

interface IAgentsProps {
  userDeports: string[];
  CountriesOptions: IOptions[];
  FiltersTransportersList: IOptionsData;
  FiltersProductsList: IOptionsData;
  FiltersAgentsList: IOptionsData;
}

const AgentDetails = (props: IAgentsProps): React.JSX.Element => {
  const { t } = useTranslation('common');
  const [searchValue, setSearchValue] = React.useState('');
  const [agentPreloadChecksListPaginationTotal, setAgentPreloadChecksListPaginationTotal] =
    useState(0);
  const [agentAnnualVettingsListPaginationTotal, setAgentAnnualVettingsPaginationTotal] =
    useState(0);
  const [preloadChecksDataTable, setPreloadChecksDataTable] = React.useState<
    IPreloadChecksDataTable[]
  >([]);
  const [annualVettingsDataTable, setAnnualVettingsDataTable] = React.useState<
    IAnnualVettingsDataTable[]
  >([]);
  const { StatusSelectField, TransporterSelectField, ProductSelectField } = FiltersMock();
  const [openEditModal, setOpenEditModal] = useState(false);
  const PreloadCheckFiltersData: IFormData[] = [
    StatusSelectField,
    {
      ...TransporterSelectField,
      optionsData: props.FiltersTransportersList,
      handleFetchMoreData: FetchFiltersTransporters,
    },
    {
      ...ProductSelectField,
      optionsData: props.FiltersProductsList,
      handleFetchMoreData: FetchFiltersProducts,
    },
  ];
  const [PreloadCheckFilters, setPreloadCheckFilters] =
    useState<IFormData[]>(PreloadCheckFiltersData);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState<IInitialValues>({
    surname: '',
    name: '',
    country: '',
    deport: '',
    role: '',
    login_id: '',
    password: '',
  });
  const [agentDetails, setAgentDetails] = useState<IAgentDetail>();
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const { PreloadCheckListColumns, AnnualVettingListColumns, AgentsModalForm } = Mock();
  const [cardAgentInfo, setCardAgentInfo] = useState<ICardItem[]>([]);
  const [AgentForm, setAgentForm] = useState<IFormData[]>(
    JSON.parse(JSON.stringify(AgentsModalForm))
  );
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(
    searchParams.get('activeTab') !== null &&
      searchParams.get('activeTab') === ChecksFilters.ANNUALVETTINGS
      ? ChecksFilters.ANNUALVETTINGS
      : ChecksFilters.PRELOADCHECKS
  );
  const agentStatusFilters = searchParams
    .get('statusFilters')
    ?.split(',')
    .map((item) => item.trim());
  const agentTransporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());
  const agentProductFilters = searchParams
    .get('productFilters')
    ?.split(',')
    .map((item) => item.trim());

  const cards: ICard[] = [
    {
      title: t('agent_information'),
      card: cardAgentInfo,
    },
  ];

  /* METHOD EMITTING API CALLS */
  /* Get Agent Data Method */
  const fetchAgent = (): void => {
    setIsRequestInProgress(true);
    getAgent(params.id as string)
      .then(async (agentData) => {
        setIsRequestInProgress(false);
        setAgentDetails(agentData);
        setAgentPreloadChecksListPaginationTotal(0);
        setAgentAnnualVettingsPaginationTotal(0);

        // Method That Sets Agent Form Values
        setAgentFormValues(agentData);
        getCardAgentInfo(agentData);
        // Find the "Deport" field in the Form array
        const deportFieldIndex = AgentForm.findIndex((field) => field.name === 'deport');
        if (deportFieldIndex !== -1) {
          AgentForm[deportFieldIndex].disabled = false;
          // Fill Options Of Deport Dropdown By Method GetCountryDeportListOptions
          AgentForm[deportFieldIndex].options = await GetCountryDeportListOptions(agentData.country);
        }
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchPreloadChecks = (offset: number): void => {
    const agentId = [params.id as string];
    setIsRequestInProgress(true);

    getPreloadChecksList(
      10,
      offset,
      searchValue,
      agentStatusFilters,
      null,
      agentTransporterFilters,
      agentProductFilters,
      agentId
    )
      .then((preloadCheckData) => {
        setIsRequestInProgress(false);
        setAgentPreloadChecksListPaginationTotal(preloadCheckData.pagination.totalPages);
        // Method That Sets Preload Checks Array Data
        setPreloadChecksDataTable(GetPreloadChecksDataTable(preloadCheckData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchAnnualVettings = (offset: number): void => {
    const agentId = [params.id as string];
    setIsRequestInProgress(true);
    getAnnualVettingsList(
      10,
      offset,
      searchValue,
      agentStatusFilters,
      null,
      agentTransporterFilters,
      agentProductFilters,
      agentId
    )
      .then((annualVettingData) => {
        setIsRequestInProgress(false);
        setAgentAnnualVettingsPaginationTotal(annualVettingData.pagination.totalPages);

        // Method That Sets Annual Vettings Array Data
        setAnnualVettingsDataTable(GetAnnualVettingsDataTable(annualVettingData.data));
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  /* Deactivate Or Activate Agent Method */
  const handleDeactivateAgent = (): void => {
    const agentStatusPayload: IAgentEditStatusPayload = {
      deport: agentDetails?.deport as string,
      role: agentDetails?.role as string,
      status: 'active',
    };

    if (agentDetails?.status === AgentStatus.ACTIVE) {
      agentStatusPayload.status = 'inactive';
    }

    setIsRequestInProgress(true);
    editAgentStatus(params.id as string, agentStatusPayload)
      .then(() => {
        setIsRequestInProgress(false);
        setOpenEditModal(false);
        showSnackbar(t('agent_has_been_edited_successfully'), 'success');
        fetchAgent();
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  /* Edit Agent Method */
  const handleEditAgent = (agentForm: IFormSchema): void => {
    setIsRequestInProgress(true);
    const agentPayload: IAgentEditPayload = {
      deport: agentForm.deport.value,
      country: agentForm.country.value,
      role: agentForm.role.value,
      pwd: agentForm.password,
    };

    editAgent(params.id as string, agentPayload)
      .then(() => {
        setIsRequestInProgress(false);
        setOpenEditModal(false);
        showSnackbar(t('agent_has_been_edited_successfully'), 'success');
        fetchAgent();
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number, isAnnualVetting: boolean): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;

    if (!isAnnualVetting) {
      fetchPreloadChecks(offset);
    } else {
      fetchAnnualVettings(offset);
    }
  };

  const GetCountryDeportListOptions = async (country: string): Promise<IOptions[]> => {
    const deportList = await getUserDeports([country]);
    return deportList.map(deport => {
      return { value: deport, label: deport };
    });
  };

  useEffect(() => {
    fetchAgent();
    void SetAgentFormOptions();
  }, []);

  useEffect(() => {
    SetFiltersData();
    /* GET CHECKS DATA */
    GetChecksData();
  }, [props, location, activeTab, searchValue]);

  // This Method Is Trigered When Country Select Change It's Value, So We Can Enable The Deport Field
  // And Pass The Right Options For It Depending On Country Value + Pass Relative Field Value To Use It On LoadOptions On Select Field Component
  const handleCountryChange = async (option: IOptions): Promise<void> => {
    const formData = AgentForm;
    // Find the "deport" field in the Form array
    const deportFieldIndex = formData.findIndex((field) => field.name === 'deport');
    if (deportFieldIndex !== -1) {
      // Make deport Field Enabled After Selecting Country Value
      formData[deportFieldIndex].disabled = false;
      // Get Country Value
      const countryValue = option.value;
      // Fill Options Of Deport Dropdown By Method GetCountryDeportListOptions
      formData[deportFieldIndex].options = await GetCountryDeportListOptions(countryValue);
      // Passing Country Value To Use It On Select Field Component To Load Options By API And Send Country Value On API
      formData[deportFieldIndex].relativeFieldValue = countryValue;
      formData[deportFieldIndex].value = undefined;
    }
  }

  const GetChecksData = (): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      fetchAnnualVettings(0);
    } else {
      fetchPreloadChecks(0);
    }
  };


  /* Fill Initial Values Agent's Form Method */
  const SetAgentFormOptions = async (): Promise<void> => {
    const formData = AgentForm;
    // Find the "country" field in the Form array
    const countryFieldIndex = formData.findIndex((field) => field.name === 'country');
    if (countryFieldIndex !== -1) {
      // Fill The options By the first default options values
      formData[countryFieldIndex].options = props.CountriesOptions;

      // Add Method To Handle Country Change
      formData[countryFieldIndex].handSelectChange = handleCountryChange;
    }

    const deportFieldIndex = formData.findIndex((field) => field.name === 'deport');
    if (deportFieldIndex !== -1) {
      formData[deportFieldIndex].options = props.userDeports.map((deport) => {
        return {
          label: deport,
          value: deport,
        };
      });
    }

    setAgentForm(formData);
  };

  const SetFiltersData = (): void => {
    const InitFiltersOptions = InitFiltersData(
      PreloadCheckFilters,
      props.FiltersTransportersList,
      props.FiltersProductsList,
      undefined,
      agentStatusFilters,
      agentTransporterFilters,
      agentProductFilters
    );
    setPreloadCheckFilters(InitFiltersOptions);
  };

  /* Fill Initial Values Agent's Form Method */
  const setAgentFormValues = (agentDetails: IAgentDetail): void => {
    const formData = AgentForm.map((field) => {
      if (field.name === 'surname' || field.name === 'name' || field.name === 'login_id') {
        field.disabled = true;
      }

      return field;
    });

    setAgentForm(formData);

    const initialValues = {
      surname: agentDetails.last_name,
      name: agentDetails.name,
      country: { label: agentDetails.country, value: agentDetails.country },
      deport: { label: agentDetails.deport, value: agentDetails.deport },
      role: { label: t(agentDetails.role), value: agentDetails.role },
      login_id: agentDetails.number_id,
    };

    setFormInitialValues(initialValues);
  };

  const editModalBtnClicked = (): void => {
    setOpenEditModal(true);
  };

  const editModalBtnClose = (): void => {
    setOpenEditModal(false);
  };

  /* Fill Details Card Method */
  const getCardAgentInfo = (agentData: IAgentDetail): void => {
    const cardAgentInfo: ICardItem[] = [
      {
        label: 'country',
        value: agentData.country,
      },
      {
        label: 'deport',
        value: agentData.deport,
      },
      {
        label: 'role',
        value: t(agentData.role),
      },

      {
        label: 'situation',
        value: agentData.is_external ? (t('agent_extern') as string) : (t('agent_intern') as string),
      },

      {
        label: 'time_spent_average',
        value:
          agentData.preload_check_average_time !== undefined
            ? agentData.preload_check_average_time
            : '-',
      },
      {
        label: 'checks',
        value: agentData.preload_checks.length.toString(),
      },
    ];

    setCardAgentInfo(cardAgentInfo);
  };

  const updateAgentsFiltersData = (newFilters: IFormData[]): void => {
    setPreloadCheckFilters(newFilters);
    const url = FiltersRouteParamsUrl(location.pathname, newFilters, activeTab);
    navigate(url);
  };

  const searchSubmit = (value: string): void => {
    setSearchValue(value);
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
  };

  const agentRowClicked = (preloadCheckId: string): void => {
    if (activeTab === ChecksFilters.ANNUALVETTINGS) {
      navigate(`/preload-checks/details/${preloadCheckId}?isAnnualVetting=true&parentTab=agents`);
    } else {
      navigate(`/preload-checks/details/${preloadCheckId}?parentTab=agents`);
    }
  };

  const handleTabChange = (tabName: string): void => {
    setActiveTab(tabName);
    const url = FiltersRouteParamsUrl(location.pathname, PreloadCheckFilters, tabName);
    navigate(url);
  };

  const GetDeleteModalConfirmButtonColor = (agentData: IAgentDetail): string => {
    return agentData.status === AgentStatus.ACTIVE ? 'senary' : 'tertiary';
  };

  const GetDeleteModalButtonLabel = (agentData: IAgentDetail): string => {
    return agentData.status === AgentStatus.ACTIVE
      ? t('deactivate_account')
      : t('activate_account');
  };

  const GetDeleteConfirmModalDescription = (agentData: IAgentDetail): string => {
    return agentData.status === AgentStatus.ACTIVE
      ? t('delete_modal_description_deactivate_account')
      : t('delete_modal_description_activate_account');
  };

  const GetDeleteModalButtonIcon = (agentData: IAgentDetail): string => {
    return agentData.status === AgentStatus.ACTIVE ? IconClose : IconAgent;
  };

  const GetDeleteModalButtonColor = (agentData: IAgentDetail): string => {
    return agentData.status === AgentStatus.ACTIVE ? 'senary' : 'black';
  };

  return (
    <div>
      {isRequestInProgress && <Loader />}

      {agentDetails !== undefined && (
        <Details
          title={`${agentDetails.last_name} ${agentDetails.name}`}
          agentStatus={agentDetails.status}
          subtitle={t('agent')}
          cards={cards}
          displayHeaderEditBtn={true}
          displayHeaderStatusBtn={true}
          editModalTitle={t('edit_agent_info')}
          modalForm={AgentForm}
          formInitialValues={formInitialValues}
          formSchema={formSchema}
          searchValue={searchValue}
          onSearchSubmit={searchSubmit}
          onSearchClear={searchHandleClear}
          preloadChecksDataTable={preloadChecksDataTable}
          annualVettingsChecksDataTable={annualVettingsDataTable}
          preloadChecksColumnsTable={PreloadCheckListColumns as IColumnTable[]}
          annualVettingsColumnsTable={AnnualVettingListColumns as IColumnTable[]}
          preloadChecksTableTableTotalPages={agentPreloadChecksListPaginationTotal}
          annualVettingsTableTableTotalPages={agentAnnualVettingsListPaginationTotal}
          filtersData={PreloadCheckFilters}
          updateFiltersData={updateAgentsFiltersData}
          onTableRowClicked={agentRowClicked}
          emptyMsgTable={t('no_preload_check_or_anual_vettings_no_data_is_available')}
          openEditModal={openEditModal}
          handleOpenEditModal={editModalBtnClicked}
          handleCloseEditModal={editModalBtnClose}
          handleEditModalSubmit={handleEditAgent}
          handleEditModalDelete={handleDeactivateAgent}
          deleteConfirmModalTitle={t('are_you_sure')}
          closeConfirmModalDescription={t('agent_info_will_be_lost')}
          deleteConfirmModalDescription={GetDeleteConfirmModalDescription(agentDetails)}
          deleteModalConfirmLabel={GetDeleteModalButtonLabel(agentDetails)}
          deleteModalConfirmColor={GetDeleteModalConfirmButtonColor(agentDetails)}
          deleteModalCancelLabel={t('cancel')}
          deleteModalButtonLabel={GetDeleteModalButtonLabel(agentDetails)}
          deleteModalButtonIcon={GetDeleteModalButtonIcon(agentDetails)}
          deleteModalButtonColor={GetDeleteModalButtonColor(agentDetails)}
          handleTablePaginationChange={handleTablePaginationChange}
          activeTabName={activeTab}
          handleChecksTabChanged={handleTabChange}
          searchPlaceholder={t('agent_search_placeholder')}
        />
      )}

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default AgentDetails;
