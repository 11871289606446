import React, { useEffect, useState } from 'react';
import './Table.scss';
import {
  MantineReactTable,
  type MRT_ColumnDef,
  type MRT_PaginationState,
  type MRT_Row,
} from 'mantine-react-table';
import { IColumnTable, IDataTable } from '../../../interfaces/Table.interface';
import { useTranslation } from 'react-i18next';

interface ITableProps {
  data: IDataTable[];
  columns: IColumnTable[];
  emptyMsg: string;
  onRowClicked: (id: string) => void;
  handlePaginationChange?: (paginationIndex: number) => void;
  totalPages: number;
  hideTableHeader?: boolean;
  hideTableBody?: boolean;
  enableSorting?: boolean;
  enableStickyHeader?: boolean;
  rowIdAccessor?: string;
  handleLoadMore?: () => void;
  nextPageLink?: string | null;
}

const Table = ({
  data,
  columns,
  emptyMsg,
  onRowClicked,
  handlePaginationChange,
  totalPages,
  hideTableHeader,
  hideTableBody,
  enableSorting,
  enableStickyHeader,
  rowIdAccessor = 'id',
  handleLoadMore,
  nextPageLink,
}: ITableProps): JSX.Element => {
  const [columnsTable, setColumnsTable] = useState<Array<MRT_ColumnDef<IDataTable>>>(columns);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [paginationCurrentPageNumber, setPaginationCurrentPageNumber] = useState(1);
  const [previousNextLink, setPreviousNextLink] = useState<string | null>(null);
  const { t } = useTranslation('common');

  // Utility function to format cell values before passing to the table
  const formatDataValue  = (value: any): string => {
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      (typeof value === 'string' && value.trim() === '') ||
      value === 'Invalid date'
    ) {
      return '-';
    }
    return value;
  };

  // Format the entire data before passing it to the table
  const formattedData = data.map((row) => {
    const formattedRow = { ...row };
    (Object.keys(row) as Array<keyof IDataTable>).forEach((key) => {
      formattedRow[key] = formatDataValue(row[key]);
    });
    return formattedRow;
  });

  const renderEmptyRowsFallback = (): React.JSX.Element => {
    return <p className="oryx-table-empty-message">{emptyMsg}</p>;
  };

  const handleRowClick = (row: MRT_Row<IDataTable>): void => {
    const rowData: IDataTable = row.original;
    const rowId = rowData[rowIdAccessor as keyof IDataTable] ?? rowData.id;
    onRowClicked(rowId);
  };

  useEffect(() => {
    const scrollContainer = document.querySelector('.mantine-Paper-root > div') as HTMLDivElement;
    if (scrollContainer === null || handleLoadMore === undefined || nextPageLink === null || nextPageLink === undefined) return;
    
    const checkAndLoadMore = (): void => {
      const isScrollable = scrollContainer.scrollHeight > scrollContainer.clientHeight;
    
      if (!isScrollable && nextPageLink !== previousNextLink) {
        handleLoadMore();
        setPreviousNextLink(nextPageLink);
      }
    };
  
    checkAndLoadMore();
  
    const handleScroll = (): void => {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
      if (scrollTop + clientHeight >= scrollHeight - 50 && nextPageLink !== previousNextLink) {
        handleLoadMore();
        setPreviousNextLink(nextPageLink);
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', checkAndLoadMore); // Handle screen size changes

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkAndLoadMore);
    };
  }, [nextPageLink, handleLoadMore, previousNextLink]);

  useEffect(() => {
    // Reset previousNextLink when nextPageLink is reset (navigation change)
    if (nextPageLink === null) {
      setPreviousNextLink(null);
    }
  }, [nextPageLink]);

  useEffect(() => {
    setPaginationCurrentPageNumber(pagination.pageIndex + 1);
  }, []);

  useEffect(() => {
    setColumnsTable(columns);
  }, [columns, t]);

  return (
    <MantineReactTable
      columns={columnsTable}
      data={formattedData }
      enableSorting={enableSorting !== undefined ? enableSorting : true}
      paginationDisplayMode="pages"
      enableColumnActions={false}
      enableTopToolbar={false}
      enableStickyHeader={enableStickyHeader !== undefined ? enableStickyHeader : false}
      enableStickyFooter
      enablePagination={data.length > 0}
      mantineTableProps={{
        sx: {
          tableLayout: 'fixed',
        },
        className: `oryx-table ${data.length === 0 ? 'empty' : ''} ${
          hideTableBody === true ? 'hide-body' : ''
        }`,
      }}
      mantineTableHeadProps={{
        className: `oryx-table-header ${hideTableHeader === true ? 'hide-header' : ''}`,
      }}
      mantineTableHeadRowProps={{
        className: 'oryx-table-header-row',
      }}
      mantineTableHeadCellProps={{
        className: 'oryx-table-header-cell',
      }}
      mantineTableBodyRowProps={({ row }) => ({
        onClick: () => {
          handleRowClick(row);
        },
        sx: {
          cursor: 'pointer',
        },
      })}
      mantinePaginationProps={{
        className: 'oryx-table-pagination',
        total: totalPages,
        value: paginationCurrentPageNumber,
        onChange: (pageNumber) => {
          setPaginationCurrentPageNumber(pageNumber as number);
          if (handlePaginationChange !== undefined) {
            const pageIndex = (pageNumber as number) - 1;
            handlePaginationChange(pageIndex);
          }
        },
      }}
      renderEmptyRowsFallback={renderEmptyRowsFallback}
      manualPagination={true}
      onPaginationChange={setPagination}
    />
  );
};

export default Table;
