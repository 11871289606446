import {
  ITransporter,
  ITransporterData,
} from '../interfaces/Transporter.interface';
import { extractAndEncode } from '../utils/string';
import { axiosAuthInstance } from './Auth.service';

export const FetchTransportersListCreateEditModal = async (): Promise<ITransporter[]> => {
  const client = await axiosAuthInstance();
  const response = await client.get('/transporter/list?limit=30&offset=0');
  return response.data.data;
};

export const FetchFiltersTransportersList = async (
  offset: string,
  search?: string,
  nextLink?: string,
): Promise<ITransporterData> => {
  const client = await axiosAuthInstance();
  let queryParams = '';
  if (search?.length != null) {
    queryParams += `&search=${search}`;
  }

  if (nextLink !== undefined) {
    queryParams += `&nextLink=${extractAndEncode(nextLink)}`;
  }
  
  const response = await client.get(`/transporter/list?limit=30&isFilter=true&offset=${offset}${queryParams}`);

  return {
    ...response.data,
    nextLink: response.data.pagination?.nextLink,
  };
};

export const FetchTransportersContractsFiltersTransportersList = async (
  offset: string,
  search?: string
): Promise<ITransporterData> => {
  let searchQuery = '';
  if (search?.length != null) {
    searchQuery += `&search=${search}`;
  }

  const client = await axiosAuthInstance();
  const response = await client.get(
    `/transporter/list?limit=30&offset=${offset}${searchQuery}`
  );

  return response.data;
};

export const FetchTrucksFiltersTransportersList = async (
  offset: string,
  search?: string
): Promise<ITransporterData> => {
  let searchQuery = '';
  if (search?.length != null) {
    searchQuery += `&search=${search}`;
  }

  const client = await axiosAuthInstance();
  const response = await client.get(
    `/truck/transporter/list?limit=30&offset=${offset}${searchQuery}`
  );

  return response.data;
};

export const FetchTrailersFiltersTransportersList = async (
  offset: string,
  search?: string
): Promise<ITransporterData> => {
  let searchQuery = '';
  if (search?.length != null) {
    searchQuery += `&search=${search}`;
  }

  const client = await axiosAuthInstance();
  const response = await client.get(
    `/trailer/transporter/list?limit=30&offset=${offset}${searchQuery}`
  );

  return response.data;
};

export const FetchDriversFiltersTransportersList = async (
  offset: string,
  search?: string
): Promise<ITransporterData> => {
  let searchQuery = '';
  if (search?.length != null) {
    searchQuery += `&search=${search}`;
  }

  const client = await axiosAuthInstance();
  const response = await client.get(
    `/driver/transporter/list?limit=30&offset=${offset}${searchQuery}`
  );

  return response.data;
};