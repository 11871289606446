import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {  IFormData, IOptions } from '../../interfaces/Form-Modal.interface';
import { IColumnTable, ITrucksDataTable } from '../../interfaces/Table.interface';
import Stats from '../../shared/components/stats/Stats';
import { useTranslation } from 'react-i18next';
import IconExport from './../../assets/icons/icon-export.svg';
import ButtonComponent from '../../shared/components/button/Button';
import { Snackbar } from '@mui/material';
import { getTrucksList } from '../../services/Truck.service';
import { ITruck } from '../../interfaces/Truck.interface';
import Loader from '../../shared/components/Loader/Loader';
import { FetchFiltersTrucksTransporters, FetchFiltersTrucksTransportersListMethod } from '../../services/Helper.service';
import { useSnackbar } from '../../services/SnackbarUtils';
import { IPagination } from '../../interfaces/Pagination.interface';
import { ExportData } from '../../services/Export.service';
import { FieldEnums } from '../../enums/Field.enum';

interface ITrucksProps {
  Transporters: IOptions[];
}

const Trucks = (props: ITrucksProps): JSX.Element => {
  const [trucksListPagination, setTrucksListPagination] = useState<IPagination>();
  const [trucksDataTable, setTrucksDataTable] = useState<ITrucksDataTable[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [nextPageLink, setNextPageLink] = useState<string | null>(null);
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>();
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const [exportUrl, setExportUrl] = useState<string>();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [TrucksFitlers, setTrucksFitlers] = useState<IFormData[]>([
    {
      componentType: FieldEnums.CUSTOMSELECT,
      label: 'transporter',
      type: 'text',
      placeholder: 'select_a_transporter',
      name: 'transporter',
      optionsData: undefined,
      handleFetchMoreData: FetchFiltersTrucksTransporters,
    },
  ]);
  const transporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());

  const truckColumnsTable: IColumnTable[] = [
    {
      accessorKey: 'truckNumber',
      header: t('truck_number'),
    },
    {
      accessorKey: 'transporter',
      header: t('transporter'),
    },
    {
      accessorKey: 'affiliate',
      header: t('affiliate'),
    },
    {
      accessorKey: 'preloadChecksNbr',
      header: t('preload_checks'),
    },
    {
      accessorKey: 'annualVettingsNbr',
      header: t('annual_vettings'),
    },
  ];

  /* METHOD EMITTING API CALLS */
  const fetchTrucksList = (offset: number, nextLink?: string): void => {
    setIsRequestInProgress(true);

    getTrucksList(10, offset, nextLink, transporterFilters, searchValue)
      .then((trucksData) => {
        setIsRequestInProgress(false);
        setIsFetchingMore(false);

        if (nextLink !== null) {
          setTrucksDataTable((prevData) => [
            ...prevData,
            ...getTrucksDataTable(trucksData.data),
          ]);
        } else {
          setTrucksDataTable(getTrucksDataTable(trucksData.data));
          setTrucksListPagination(trucksData.pagination);
        }

        // Store the next page link for infinite scroll
        setNextPageLink(trucksData.nextLink ?? null);
        setExportUrl(trucksData.exportUrl);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        setIsFetchingMore(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;
    fetchTrucksList(offset);
  };

  /* Export Method */
  const TrucksExport = (): any => {
    if (exportUrl !== undefined) {
      setIsRequestInProgress(true);

      ExportData(exportUrl)
        .then(() => {
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  const SetFiltersData = async (): Promise<void> => {
    const FiltersTransportersOptionsData = await FetchFiltersTrucksTransportersListMethod('0');

    const Filters = TrucksFitlers.map((filter) => {
      filter.optionsData = FiltersTransportersOptionsData;
      filter.optionsData.options = filter.optionsData.options.map((option) => {
        const found = transporterFilters?.find((id: string) => id === option.value);

        return {
          ...option,
          checked: found !== undefined, // Set checked to true if found in ids
        };
      });
      return filter;
    });

    setTrucksFitlers(Filters);
  };

  /* OTHER METHODS */
  const getTrucksDataTable = (trucksData: ITruck[]): ITrucksDataTable[] => {
    return trucksData.map((truck) => {
      return {
        id: truck.id,
        truckNumber: truck.truck_number.length !== 0 ? truck.truck_number : '-',
        transporter: truck.transporter,
        affiliate: truck.affiliate,
        preloadChecksNbr: truck.nbChecks,
        annualVettingsNbr: truck.nbAnnualChecks,
      };
    });
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
  };

  const handleSubmitSearch = (value: string): void => {
    setSearchValue(value);
  };

  const handleTableRowClicked = (truckId: string): void => {
    navigate(`/trucks/details/${truckId}`);
  };

  const updateFiltersData = (newFilters: IFormData[]): void => {
    setTrucksFitlers(newFilters);
    const transporterIds = newFilters
      .find((filter) => filter.name === 'transporter')
      ?.optionsData?.options.filter((option) => option.checked)
      .map((option) => option.value);

    let queryString = '';
    if (transporterIds !== undefined) {
      queryString = `?transporterFilters=${transporterIds.join(',')}`;

      if (transporterIds.length > 0) {
        navigate('/trucks' + queryString);
      } else navigate('/trucks');
    }
  };

  const setTrucksHeaderButtons = (): React.JSX.Element => {
    return (
      <>
        {exportUrl !== undefined && (
          <ButtonComponent
            iconUrl={IconExport}
            iconAlt="icon-export"
            label={t('export')}
            color="secondary btn-transparent btn-outlined"
            onClick={TrucksExport}
          />
        )}
      </>
    );
  };

  const filteredTrucks = useMemo(() => {
    if (searchValue === undefined || searchValue.trim() === '' || searchValue === null) return trucksDataTable;

    return trucksDataTable.filter((truck) =>
      Object.values(truck).some((value) =>
        typeof value === 'string' && value.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue, trucksDataTable]);

  const handleLoadMore = (): void => {
    if (nextPageLink === null || isFetchingMore) return;
    setIsFetchingMore(true);
    fetchTrucksList(0, nextPageLink);
  };

  useEffect(() => {
    setTrucksDataTable([]);
    setNextPageLink(null);
    void SetFiltersData();
    fetchTrucksList(0);
  }, [props, location]);

  return (
    <div>
      {isRequestInProgress === true && <Loader />}

      <Stats
        searchValue={searchValue}
        onSearchSubmit={handleSubmitSearch}
        onSearchClear={searchHandleClear}
        headerButtons={setTrucksHeaderButtons()}
        dataTable={filteredTrucks}
        columnsTable={truckColumnsTable}
        emptyMsgTable={isRequestInProgress === false ? t('no_truck_to_display') : ''}
        onTableRowClicked={handleTableRowClicked}
        filtersData={TrucksFitlers}
        updateFiltersData={updateFiltersData}
        handleTablePaginationChange={handleTablePaginationChange}
        tableTotalPages={trucksListPagination !== undefined ? trucksListPagination.totalPages : 0}
        searchPlaceholder={t('truck_search_placeholder')}
        nextPageLink={nextPageLink}
        handleLoadMore={handleLoadMore}
      />

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default Trucks;
