import React, { useEffect } from 'react';
import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { IOptions } from '../../../interfaces/Form-Modal.interface';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

import './SelectField.scss';
import { useTranslation } from 'react-i18next';

interface ISelectFieldProps {
  label: string;
  name: string;
  options: IOptions[];
  field: any;
  form: any;
  wide?: boolean;
  handleChange?: (option: IOptions) => void;
  handleSearchLoadOptions?: (inputValue: string, params: { affiliate?: string; accessToken?: string }, callback: (options: IOptions[]) => void) => void;
  CustomHandleSearchLoadOptions?: (affiliate: string, inputValue: string, callback: (options: IOptions[]) => void) => void;
  disabled?: boolean;
  relativeFieldValue?: string;
  relativeFieldName?: string;
  value?: string;
}

const SelectField = ({
  label,
  name,
  field,
  form,
  wide,
  options,
  handleSearchLoadOptions,
  CustomHandleSearchLoadOptions,
  handleChange,
  disabled,
  value,
  relativeFieldName,
  relativeFieldValue,
}: ISelectFieldProps): React.JSX.Element => {
  const { t } = useTranslation('common');

  const LoadOptions = (inputValue: string, callback: (options: IOptions[]) => void): void => {
    // Use Custom Search Method When A Field Depends On Another One
    if (CustomHandleSearchLoadOptions !== undefined && relativeFieldValue !== undefined) {
      CustomHandleSearchLoadOptions(relativeFieldValue, inputValue, callback);
    }

    if (handleSearchLoadOptions !== undefined) {
      handleSearchLoadOptions(inputValue, {}, callback);
    } 
  }

  const getHelperText = (): string => {
    return IsError() ? t('information_is_required') : '';
  };

  const IsError = (): boolean => {
    return Boolean(form.touched[name]) && Boolean(form.errors[name]);
  };

  useEffect(() => {
    // Script To Update Transporter Select When Affiliate Has Changed
    if (name === 'affiliate' && relativeFieldName !== undefined && form.touched[name] === true) {
      form.setFieldValue(relativeFieldName, '');
    }

    if (name === 'transporter' && relativeFieldValue !== undefined && value === null && field.value.length > 0) {
      form.setFieldValue('transporter', field.value);
    }

    // Script To Update Deport Select When Country Has Changed
    if (name === 'country' && relativeFieldName !== undefined && form.touched[name] === true) {
      form.setFieldValue(relativeFieldName, '');
    }

    if (name === 'deport' && relativeFieldValue !== undefined && value === null && field.value.length > 0) {
      form.setFieldValue('deport', field.value);
    }
  }, [value]);

  return (
    <div className={`block-select ${wide === true ? 'block-wide' : ''} ${IsError() ? 'error' : ''} ${disabled === true ? 'disabled' : ''}`}>
      <FormControl sx={{ m: 1, minWidth: 120 }} error={IsError()}>
        <InputLabel shrink={true}>{label}</InputLabel>
        {handleSearchLoadOptions === undefined && CustomHandleSearchLoadOptions === undefined ? (
          <Select
            name={field.name}
            value={field.value}
            isDisabled={disabled}
            onChange={(option) => {
              if (handleChange !== undefined) {
                handleChange(option)
              }
              form.getFieldHelpers(name).setValue(option);
              form.getFieldHelpers(name).setTouched(true);
              form.setFieldValue(name, option);
            }}
            onBlur={() => {
              form.getFieldHelpers(name).setTouched(true);
              form.getFieldHelpers(name).setError(true);
            }}
            options={options}
            isSearchable={false}
            placeholder={null}
          />
        ) : (
          <AsyncSelect
            cacheOptions
            name={field.name}
            value={value === null ? value : field.value}
            isDisabled={disabled}
            loadOptions={LoadOptions}
            defaultOptions={options}
            onChange={(option) => {
              form.getFieldHelpers(name).setValue(option);
              form.getFieldHelpers(name).setTouched(true);
              form.setFieldValue(name, option);
            }}
            onBlur={() => {
              form.getFieldHelpers(name).setTouched(true);
              form.getFieldHelpers(name).setError(true);
            }}
            placeholder={null}
          />
        )}

        <FormHelperText>{getHelperText()}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default SelectField;
