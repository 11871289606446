// Utility function to capitalize the first letter of a string
export const capitalize = (msg: string): string => {
    return msg.charAt(0).toUpperCase() + msg.slice(1);  
}

export const decodeJwtToken = (token: string): any => {
    const base64Url = token.split('.')[1]; // Get payload part
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Base64 standard adjustments
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const extractAndEncode = (url: string): string => {
    const searchParams = new URL(url).search;
    return encodeURIComponent(searchParams);
};