import React, { useState, useEffect } from 'react';
import './Filter-Modal.scss';

import Modal from '@mui/material/Modal';
import { Slide, Box } from '@mui/material';
import IconClose from './../../../assets/icons/icon-close.svg';
import IconCloseBlack from './../../../assets/icons/icon-close-black.svg';
import ButtonComponent from '../button/Button';
import { IFormData, IOptions, IOptionsData } from '../../../interfaces/Form-Modal.interface';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import IconFullRightBlack from './../../../assets/icons/icon-full-chevron-right-black.svg';
import CustomSelect from '../custom-select/CustomSelect';
import { FieldEnums } from '../../../enums/Field.enum';
import DatepickerField from '../DatepickerField/DatepickerField';
import moment from 'moment';

interface IFormModalProps {
  openModal: boolean;
  onClose: () => void;
  filtersData: IFormData[];
  updateFiltersData?: (filters: IFormData[]) => void;
  handleFetchMoreData?: (name: string) => Promise<IOptionsData>;
}

type InitialValues = Record<string, IOptions[]> | Record<string, string | Date | undefined>;

const FilterModal = (props: IFormModalProps): React.JSX.Element => {
  const { onClose, openModal, filtersData, updateFiltersData } = props;
  const { t } = useTranslation('common');
  const GetFitlersCopy = (): IFormData[] => {
    const PreloadCheckFitlersDataCopy = [...filtersData].map((filter) => {
      if (filter.optionsData !== undefined) {
        return { ...filter, optionsData: JSON.parse(JSON.stringify(filter.optionsData)) };
      } else return filter;
    });

    return PreloadCheckFitlersDataCopy;
  };

  const [formData, setFormData] = useState<IFormData[]>(GetFitlersCopy());
  const [initialValues, setInitialValues] = useState<InitialValues>({});
  const [validationSchema, setValidationSchema] = useState({});

  useEffect(() => {
    setFormData(GetFitlersCopy());
    const initialValues: InitialValues = {};
    const schema: Record<string, any> = {};

    Yup.object().shape(schema);

    formData.forEach((form) => {
      // Filter the options where checked is true
      const checkedOptions = form.optionsData?.options.filter((option) => option.checked);

      // Create a property with the filter's name and set its value
      // to the array of checked option values
      if (form.componentType === FieldEnums.DATEPICKER) {
        initialValues[form.name] = form.value === undefined ?  undefined : form.value;
        schema[form.name] = Yup.date();
      } else {
        initialValues[form.name] = checkedOptions === undefined ? [] : checkedOptions;
        schema[form.name] = Yup.array();
      }
    });

    setInitialValues(initialValues);
    setValidationSchema(Yup.object().shape(schema));
  }, [openModal, filtersData, t]);

  const handleClose = (): void => {
    onClose();
  };

  const handleStatusFiltersClick = (statusForm: IFormData, option: IOptions): void => {
    const data = formData.map((form) => {
      if (form.name === statusForm.name) {
        form.optionsData?.options.forEach((optionItem) => {
          if (option.value === optionItem.value) {
            optionItem.checked = !(optionItem.checked ?? false);
          }

          return form;
        });

        return statusForm;
      }

      return form;
    });
    setFormData(data);
  };

  const resetFilters = (): void => {
    const formDataAfterReset = formData.map((form) => {
      if (form.componentType === FieldEnums.DATEPICKER) {
        form.value = undefined;
      } else {
        form.optionsData?.options.forEach((option) => {
          option.checked = false;
        });
      }

      return form;
    });
    if (updateFiltersData !== undefined) {
      updateFiltersData(formDataAfterReset);
    }

    handleClose();
  };

  const statusFieldComponent = (frmData: IFormData): React.JSX.Element => {
    return (
      <div className="block-status" key={`status-field-key-${frmData.name}`}>
        <p className="block-status__label">{t(frmData.label)}</p>
        <div className="block-status__container">
          {frmData.optionsData?.options?.map((option, index) => (
            <ButtonComponent
              disabled={frmData.disabled}
              key={`status-option-key-${index}`}
              classes="tabs"
              label={t(option.label)}
              color={option.checked ?? false ? 'quinary' : 'secondary'}
              onClick={() => {
                handleStatusFiltersClick(frmData, option);
              }}
            />
          ))}
        </div>
      </div>
    );
  };

  const customFieldComponent = (frmData: IFormData): React.JSX.Element => {
    return (
      <Field key={frmData.name} name={frmData.name}>
        {({ field, form }: any) => (
          <CustomSelect
            key={`custom-select-key-${frmData.name}`}
            name={frmData.name}
            label={t(frmData.label)}
            placeholder={t(frmData.placeholder as string)}
            field={field}
            form={form}
            optionsData={frmData.optionsData as IOptionsData}
            handleFetchMoreData={frmData.handleFetchMoreData}
          />
        )}
      </Field>
    );
  };
  
  const FilterDatePickerFieldComponent = (formField: IFormData): React.JSX.Element => {
    return (
      <Field key={`date-picker-key-${formField.name}`} name={formField.name}>
        {({ field, form }: any) => (
          <DatepickerField
            name={formField.name}
            label={t(formField.label)}
            disabled={formField.disabled}
            placeholder={t(formField.placeholder as string)}
            field={field}
            form={form}
          />
        )}
      </Field>
    );
  };

  const displayFilterFields = (formItem: IFormData): React.JSX.Element | null => {
    if (formItem.componentType === FieldEnums.CUSTOMSELECT) {
      return customFieldComponent(formItem);
    } else if (formItem.componentType === FieldEnums.CHIP) {
      return statusFieldComponent(formItem);
    } else if (formItem.componentType === FieldEnums.DATEPICKER) {
      return FilterDatePickerFieldComponent(formItem);
    }

    return null;
  };

  const handleFilterClick = (modalResult: any): void => {
    formData.forEach((filter) => {
      const filterName = filter.name;
      if (modalResult !== undefined && filterName in modalResult && filter.componentType !== FieldEnums.CHIP) {
        if (filter.componentType === FieldEnums.CUSTOMSELECT) {
          const checkedOptionsValues = modalResult[filterName].map((options: any) => options.value);
          const selectedOptions = checkedOptionsValues.map((value: string) => value);
          if (checkedOptionsValues.length > 0) {
            if (filter.optionsData !== undefined) {
              filter.optionsData.options = filter.optionsData?.options.map((option) => ({
                ...option,
                checked: selectedOptions.includes(option.value),
              }));
            } else {
              // Update the options in the filter based on the selectedOptions
              filter.options = filter.options?.map((option) => ({
                ...option,
                checked: selectedOptions.includes(option.value),
              }));
            }
          } else {
            if (filter.optionsData !== undefined) {
              filter.optionsData.options = filter.optionsData?.options.map((option) => ({
                ...option,
                checked: selectedOptions.includes(option.value),
              }));
            }
          }
        } else {
          if (modalResult[filterName] !== undefined && modalResult[filterName].length !== 0) {
            filter.value = moment(modalResult[filterName]).format('YYYY-MM-DD');
          }
        }

      }
      return filter;
    });

    // Send Data Updated To Parent Component
    if (updateFiltersData !== undefined) {
      updateFiltersData(formData);
    }

    handleClose();
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Slide in={openModal} direction="left" timeout={600}>
        <Box>
          <div className={`filter-modal ${openModal ? 'open' : 'close'}`}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFilterClick}
            >
              {({ values }) => (
                <Form>
                  <div className="filter-top">
                    <div className="filter-header">
                      <h3>{t('filter')}</h3>
                      <ButtonComponent
                        classes="no-shadow"
                        iconOnly={true}
                        iconRound={true}
                        iconUrl={IconClose}
                        iconAlt="icon-close"
                        onClick={handleClose}
                      />
                    </div>
                    <div className="filter-body">
                      {/* Loop through formData Variable and display form fields */}
                      {formData.map((frmData) => {
                        return displayFilterFields(frmData);
                      })}
                    </div>
                  </div>
                  <div className="filter-footer">
                    <ButtonComponent
                      iconUrl={IconFullRightBlack}
                      iconAlt="icon-right"
                      label={t('filter')}
                      color="tertiary"
                      onClick={() => {
                        handleFilterClick(values);
                      }}
                    />

                    <ButtonComponent
                      iconUrl={IconCloseBlack}
                      iconAlt="icon-close"
                      label={t('reset')}
                      color="secondary btn-outlined"
                      onClick={() => {
                        resetFilters();
                      }}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Box>
      </Slide>
    </Modal>
  );
};

export default FilterModal;
