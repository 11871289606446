import { msalConfig } from '../config/authConfig';
import { PublicClientApplication, AuthenticationResult, AccountInfo, EventType } from '@azure/msal-browser';

// Create an MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

class AuthStore {
    private accessToken: string | null = null;
    private account: AccountInfo | null = null;

    async initialize(): Promise<void> {
        await msalInstance.initialize();
        if (msalInstance.getActiveAccount() === null && msalInstance.getAllAccounts().length > 0) {
            msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
        }

        // Enable account storage events to keep session synchronized across tabs
        msalInstance.enableAccountStorageEvents();

        msalInstance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS
                ||
                event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                ||
                event.eventType === EventType.SSO_SILENT_SUCCESS
            ) {
                if (event.payload !== null && 'account' in event.payload && event.payload.account !== undefined) {
                    const account = event.payload.account as AccountInfo;
                    msalInstance.setActiveAccount(account);
                    this.account = account;
                    if ('accessToken' in event.payload) {
                        this.accessToken = event.payload.accessToken;
                    }
                }
            }
        });
    }

    async acquireToken(): Promise<string | null> {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            try {
                const response: AuthenticationResult = await msalInstance.acquireTokenSilent({
                    scopes: ['User.Read', 'Group.Read.All'],
                    account: accounts[0],
                });
                this.accessToken = response.accessToken;
                this.account = accounts[0];
                return this.accessToken;
            } catch (error) {
                console.error('Token acquisition failed', error);
                return null;
            }
        }
        return null;
    }

    getToken(): string | null {
        return this.accessToken;
    }

    getAccount(): AccountInfo | null {
        return this.account;
    }
}

// Export a singleton instance
const authStore = new AuthStore();
export { authStore, msalInstance };