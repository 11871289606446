import './App.scss';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Snackbar } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import { EventMessage, EventType } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { authStore } from './stores/authStore';
// Shared
import Layout from './shared/components/layout/Layout';
import Loader from './shared/components/Loader/Loader';
import { decodeJwtToken } from './utils/string';
// Pages
import AgentDetails from './pages/agent-details/AgentDetails';
import Agents from './pages/agents/Agents';
import AnnualVettings from './pages/annual-vettings/AnnualVettings';
import Dashboard from './pages/dashboard/Dashboard';
import DriverDetails from './pages/driver-details/DriverDetails';
import Drivers from './pages/drivers/Drivers';
import Login from './pages/login/Login';
import PreloadChecks from './pages/preload-checks/PreloadChecks';
import PreloadCheckDetails from './pages/preload-checks-details/PreloadCheckDetails';
import PreloadCheckEntire from './pages/preload-check-entire/PreloadCheckEntire';
import TruckDetails from './pages/truck-details/TruckDetails';
import Trailers from './pages/trailers/Trailers';
import TrailerDetails from './pages/trailers-details/TrailerDetails';
import TransporterDetails from './pages/transporter-details/TransporterDetails';
import Transporters from './pages/transporters/Transporters';
import Trucks from './pages/trucks/Trucks';
// Interfaces
import { IUser, IUserSSOPayload, UserRole } from './interfaces/User.interface';
import { IAccessTokenInfo, IEventPayload } from './interfaces/Account.interface';
import { IOptions, IOptionsData } from './interfaces/Form-Modal.interface';
// Services
import {
  FetchFiltersAgentsListMethod,
  FetchFiltersProductsListMethod,
  FetchFiltersTransportersListMethod,
} from './services/Helper.service';
import { useSnackbar } from './services/SnackbarUtils';
import { createSSOUser } from './services/Auth.service';
import { editCurrentUserDeport, getCurrentUser } from './services/User.service';
import { FetchProductsListCreateEditModal } from './services/Product.service';
import { FetchTransportersListCreateEditModal } from './services/Transporter.service';
import { GetCountries } from './services/Country.service';

const App = (): React.JSX.Element => {
  const { instance } = useMsal();
  const [userName, setUserName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userDeports, setUserDeports] = useState<string[]>([]);
  const [currentUser, setCurrentUser] = useState<IUser>();
  const [ProductsList, setProductsList] = useState<IOptions[]>([]);
  const [TransportersList, setTransportersList] = useState<IOptions[]>([]);
  const [Countries, setCountries] = useState<IOptions[]>([]);
  const [FiltersProductsList, setFiltersProductsList] = useState<IOptionsData>();
  const [PreloadCheckFiltersTransportersList, setPreloadCheckFiltersTransportersList] = useState<IOptionsData>();
  const [FiltersAgentsList, setFiltersAgentsList] = useState<IOptionsData>();
  const [deportChanged, setDeportChanged] = useState(false);
  const [_accessToken, setAccessToken] = useState<string | null>(null);
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();

  const logoutMethod = (): void => {
    void instance.logout({
      onRedirectNavigate: () => {
        setIsLoading(false);
        localStorage.clear();
        Cookies.remove('token');
        Cookies.remove('refreshToken');
        // Return false to prevent the redirect.
        return false;
      },
    });
  };

  const loginMethod = async (event: EventMessage): Promise<void> => {
    try {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS || 
        event.eventType === EventType.SSO_SILENT_SUCCESS || 
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload !== null
      ) {

        setIsLoading(true);

        if ('account' in event.payload && event.payload.account !== undefined) {

          const payloadEvent: IEventPayload = event.payload as IEventPayload;
          await getUserData(payloadEvent.accessToken, payloadEvent.account.idTokenClaims);
        } else {
          console.debug('Logout initiated due to missing account info.');
          logoutMethod();
        }
      }
    } catch (error) {
      console.error('Error during loginMethod execution:', error);
      logoutMethod();
    }
  };

  const getUserData = async (accessToken: string, idTokenClaims: any): Promise<void> => {
    if (accessToken === undefined || accessToken === null) {
      console.debug('No access token available, logging out.');
      logoutMethod();
      return;
    }
    
    try {
      const accessTokenInfo: IAccessTokenInfo = decodeJwtToken(accessToken) as IAccessTokenInfo;

      // Extract role from idTokenClaims.roles (default to 'guest' if no role)
      const roles = idTokenClaims?.roles ?? [];
      const role = getHighestPriorityRole(roles);

      // Fetch user deports using the correct access token
      const deports = await fetchUserDeports(accessToken);
      const deport = deports.length > 0 ? deports[0] : '';
      setUserDeports(deports);

      // Extract user details with fallback logic
      const name = accessTokenInfo.given_name?.trim() !== '' 
        ? accessTokenInfo.given_name?.trim() 
        : accessTokenInfo.name?.trim() !== '' 
        ? accessTokenInfo.name?.trim() 
        : '';

      const lastName = accessTokenInfo.family_name?.trim() !== '' 
        ? accessTokenInfo.family_name?.trim()
        : name !== '' ? name : '';

        const payload = {
        name,
        last_name: lastName,
        email: accessTokenInfo.email?.trim() !== '' ? accessTokenInfo.email?.trim() : '',
        deport,
        role,
        number_id: accessTokenInfo.oid ?? '',
        external_id: accessTokenInfo.oid ?? '',
      };
  
      // Validate before setting user data
      validatePayload(payload);
  
      await createSSOUser(payload).then(fetchFilters);
  
      setUserName(payload.name);
    } catch (error) {
      console.error('Error in getUserData:', error);
      logoutMethod();
    }
  };

  const getHighestPriorityRole = (roles: string[]): string => {
    return roles
      .map(role => role.toLowerCase())
      .find(role => [UserRole.Manager, UserRole.SecurityAgent, UserRole.Agent].includes(role as UserRole)) ?? '';
  };

  const validatePayload = (payload: IUserSSOPayload): void => {
    for (const [key, value] of Object.entries(payload)) {
      if (value === undefined || value === null || value === '') {
        if (key === 'deport') {
          console.error('Validation Error: You are not assigned to a Deport.');
          showSnackbar('You are not assigned to a Deport.', 'error');
        } else if (key === 'role') {
          console.error('Validation Error: You are not assigned to a Role.');
          showSnackbar('You are not assigned to a Role.', 'error');
        } else {
          console.error(`Validation Error: ${key} is missing.`);
          showSnackbar(`Your ${key} is missing from Microsoft account.`, 'error');
        }
      }
    }
  };

  const fetchUserDeports = async (token: string): Promise<string[]> => {
    let depots: string[] = [];

    try {
      const displayName = 'DW_TruckVetting_Depot_';
      
      const response = await axios.get<{ value: string[] }>(
        `https://graph.microsoft.com/v1.0/me/memberOf?$search="displayName:${displayName}"`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            ConsistencyLevel: 'eventual',
          },
        }
      );

      const depotItems = response.data?.value ?? [];

      depots = depotItems
        .filter((depot: any) => 'displayName' in depot)
        .map((depot: any) => {
          const depotName = depot.displayName.split('_');
          return depotName[depotName.length - 1];
        });
  
    } catch (error) {
      console.error('Error fetching depots:', error);
    }

    return depots;
  };

  const getCurrentUserData = (): void => {
    getCurrentUser()
      .then((currentUser) => {
        setCurrentUser(currentUser);
        setIsLoading(false);
      })
      .catch((error) => {
        showSnackbar(error.message, 'error');
        setIsLoading(false);
      });
  };

  const getCountriesMethod = (): void => {
    GetCountries().then((countries) => {
      setIsLoading(false);
      setCountries(countries)
    })
    .catch((error) => {
      showSnackbar(error.message, 'error');
      setIsLoading(false);
    });
  };

  const editUserDeport = (deport: string): void => {
    setDeportChanged(false);

    const payload = { deport };
    editCurrentUserDeport(payload)
      .then(() => {
        setDeportChanged(true);
      })
      .catch((error) => {
        showSnackbar(error.message, 'error');
      });
  };

  const fecthProducts = (): void => {
    FetchProductsListCreateEditModal()
      .then((productsList) => {
        const productsOptionsList = productsList.map((product) => {
          return { value: product.id, label: product.description };
        });

        setProductsList(productsOptionsList);
      })
      .catch((error) => {
        showSnackbar(error.message, 'error');
      });
  };

  const fecthTransporters = (): void => {
    FetchTransportersListCreateEditModal()
      .then((transportersList) => {
        const transportersOptionsList = transportersList.map((transporter) => {
          return { value: transporter.id, label: transporter.name };
        });

        setTransportersList(transportersOptionsList);
      })
      .catch((error) => {
        showSnackbar(error.message, 'error');
      });
  };

  const fetchFiltersProductsList = (): void => {
    setIsLoading(true);
    FetchFiltersProductsListMethod('0')
      .then((productsList) => {
        setIsLoading(false);
        setFiltersProductsList(productsList);
      })
      .catch((error) => {
        setIsLoading(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchFiltersTransportersList = (): void => {
    setIsLoading(true);
    FetchFiltersTransportersListMethod('0')
      .then((transportersList) => {
        setIsLoading(false);
        setPreloadCheckFiltersTransportersList(transportersList);
      })
      .catch((error) => {
        setIsLoading(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchFiltersAgentsList = (): void => {
    setIsLoading(true);
    FetchFiltersAgentsListMethod('0')
      .then((agentsList) => {
        setIsLoading(false);
        setFiltersAgentsList(agentsList);
      })
      .catch((error) => {
        setIsLoading(false);
        showSnackbar(error.message, 'error');
      });
  };

  const fetchFilters = async (): Promise<void> => {
      getCurrentUserData();
      // Modal Add Edit List Options
      fecthProducts();
      fecthTransporters();
      getCountriesMethod();
      // Filters List Options
      fetchFiltersProductsList();
      fetchFiltersTransportersList();
      fetchFiltersAgentsList();
  };

  useEffect(() => {
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      // Load stored token
      void authStore.acquireToken().then((token) => {
        setAccessToken(token);
        setIsLoading(true);
        if (token !== null) {
          void getUserData(token, accounts[0].idTokenClaims);
        }
      });
    }

    // Add event callback for login success
    const callbackId = instance.addEventCallback((event) => {
      void loginMethod(event);
    });

    return () => {
      if (callbackId !== null) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, deportChanged]);

  return (
    <div className="App">
      <UnauthenticatedTemplate>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {!isLoading ? (
          <Routes>
            {/* Redirect base URL to /dashboard */}
            <Route path="/" element={<Navigate replace to="/dashboard" />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
            
            <Route 
              path="/"
              element={
                <Layout
                  currentUser={currentUser as IUser}
                  userDeports={userDeports}
                  handleEditCurrentUserDeport={editUserDeport}
                />
              }
            >
              {/* Dashboard Route */}
              <Route path="/dashboard">
                <Route
                  path="/dashboard/:activeTab?/:periodFilter?"
                  element={<Dashboard userName={userName} />}
                />
                <Route
                  path="preload-checks/:checkStatus?/:id"
                  element={<PreloadCheckDetails />}
                />
              </Route>

              {/* Preload Checks */}
              <Route path="/preload-checks">
                <Route
                  path="/preload-checks/:statusFilters?/:transporterFilters?/:productFilters?/:agentFilters?"
                  element={
                    <PreloadChecks
                      FiltersTransportersList={PreloadCheckFiltersTransportersList as IOptionsData}
                      FiltersProductsList={FiltersProductsList as IOptionsData}
                      FiltersAgentsList={FiltersAgentsList as IOptionsData}
                    />
                  }
                />
                <Route path="details/:id" element={<PreloadCheckDetails />} />
                <Route path="entire-check/:id" element={<PreloadCheckEntire />} />
              </Route>

              {/* Annual Vettings */}
              <Route
                path="/annual-vettings/:statusFilters?/:transporterFilters?/:productFilters?/:agentFilters?"
                element={
                  <AnnualVettings
                    FiltersTransportersList={PreloadCheckFiltersTransportersList as IOptionsData}
                    FiltersProductsList={FiltersProductsList as IOptionsData}
                    FiltersAgentsList={FiltersAgentsList as IOptionsData}
                  />
                }
              />

              {/* Trucks */}
              <Route path="/trucks">
                <Route
                  path="/trucks/:transporterFilters?"
                  element={
                    <Trucks
                      Transporters={TransportersList}
                    />
                  }
                />

                <Route
                  path="details/:id"
                  element={
                    <TruckDetails
                      transporters={TransportersList}
                      FiltersTransportersList={PreloadCheckFiltersTransportersList as IOptionsData}
                      FiltersProductsList={FiltersProductsList as IOptionsData}
                      FiltersAgentsList={FiltersAgentsList as IOptionsData}
                    />
                  }
                />
              </Route>

              {/* Drivers */}
              <Route path="/drivers">
                <Route
                  path="/drivers/:transporterFilters?"
                  element={
                    <Drivers
                      Transporters={TransportersList}
                    />
                  }
                />
                <Route
                  path="details/:id/:activeTab?/:statusFilters?/:productFilters?/:productFilters?/:agentFilters?"
                  element={
                    <DriverDetails
                      transporters={TransportersList}
                      FiltersTransportersList={PreloadCheckFiltersTransportersList as IOptionsData}
                      FiltersProductsList={FiltersProductsList as IOptionsData}
                      FiltersAgentsList={FiltersAgentsList as IOptionsData}
                    />
                  }
                />
              </Route>

              {/* Agents */}
              <Route path="/agents">
                <Route
                  path="/agents/:roleFilters?/:deportFilters?"
                  element={<Agents CountriesOptions={Countries} />}
                />
                <Route
                  path="details/:id"
                  element={
                    <AgentDetails
                      userDeports={userDeports}
                      CountriesOptions={Countries}
                      FiltersTransportersList={PreloadCheckFiltersTransportersList as IOptionsData}
                      FiltersProductsList={FiltersProductsList as IOptionsData}
                      FiltersAgentsList={FiltersAgentsList as IOptionsData}
                    />
                  }
                />
              </Route>

              {/* Transporters */}
              <Route path="/transporters">
                <Route
                  path="/transporters/:transporterFilters?"
                  element={
                    <Transporters
                      products={ProductsList}
                    />
                  }
                />
                <Route
                  path="details/:id/:activeTab?/:statusFilters?/:productFilters?/:agentFilters?"
                  element={
                    <TransporterDetails
                      products={ProductsList}
                      FiltersTransportersList={PreloadCheckFiltersTransportersList as IOptionsData}
                      FiltersProductsList={FiltersProductsList as IOptionsData}
                      FiltersAgentsList={FiltersAgentsList as IOptionsData}
                    />
                  }
                />
              </Route>

              {/* Trailers */}
              <Route path="/trailers">
                <Route
                  path="/trailers/:transporterFilters?/:productFilters?"
                  element={
                    <Trailers
                      transporters={TransportersList}
                      products={ProductsList}
                    />
                  }
                />
                <Route
                  path="details/:id/:activeTab?/:statusFilters?/:productFilters?/:productFilters?/:agentFilters?"
                  element={
                    <TrailerDetails
                      transporters={TransportersList}
                      products={ProductsList}
                      FiltersTransportersList={PreloadCheckFiltersTransportersList as IOptionsData}
                      FiltersProductsList={FiltersProductsList as IOptionsData}
                      FiltersAgentsList={FiltersAgentsList as IOptionsData}
                    />
                  }
                />
              </Route>
            </Route>
          </Routes>
        ) : (
          <Loader />
        )}
      </AuthenticatedTemplate>

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default App;
