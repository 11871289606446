import {
  ITransporterContract,
  ITransporterContractData,
} from '../interfaces/TransporterContract.interface';
import { extractAndEncode } from '../utils/string';
import { axiosAuthInstance } from './Auth.service';

export const getTransportersContractsList = async (
  limit: number,
  offset: number,
  nextLink?: string,
  transporterIds?: string[],
  searchQuery?: string
): Promise<ITransporterContractData> => {
  const client = await axiosAuthInstance();
  let queryParams = '';

  transporterIds?.forEach((filter) => {
    queryParams += `&transporterIds[]=${filter}`;
  });

  if (searchQuery !== undefined && searchQuery.length > 0) {
    queryParams += `&search=${searchQuery}`;
  }

  if (nextLink !== undefined) {
    queryParams += `&nextLink=${extractAndEncode(nextLink)}`;
  }

  const response = await client.get(`/transporter/list?limit=${limit}&offset=${offset}${queryParams}`);

  return {
    ...response.data,
    nextLink: response.data.pagination?.nextLink,
  };
};

export const getTransporterContract = async (id: string): Promise<ITransporterContract> => {
  const client = await axiosAuthInstance();
  const response = await client.get(`/transporter/${id}`);
  return response.data;
};