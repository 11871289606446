import React from 'react';
import moment from 'moment';
import { PreloadCheckStatus } from '../enums/Status.enum';
import { ICard } from '../interfaces/Details.interface';
import { IFormData, IOptions, IOptionsData } from '../interfaces/Form-Modal.interface';
import { IAnnualVetting, IPreloadCheck } from '../interfaces/PreloadCheck.interface';
import { IAnnualVettingsDataTable, IPreloadChecksDataTable } from '../interfaces/Table.interface';
import { FetchFiltersAgentsList } from './Agent.service';
import { FetchFiltersProductsList, FetchProductsList } from './Product.service';
import { 
  FetchDriversFiltersTransportersList, 
  FetchFiltersTransportersList, 
  FetchTrailersFiltersTransportersList, 
  FetchTransportersContractsFiltersTransportersList, 
  FetchTrucksFiltersTransportersList 
} from './Transporter.service';
import { searchManagersList } from './Manager.service';

const filterDataTableSource = (
  searchInput: string,
  data: IPreloadChecksDataTable[] | IAnnualVettingsDataTable[],
  properties: string[]
): IPreloadChecksDataTable[] | IAnnualVettingsDataTable[] => {
  const filteredArray = [];
  for (const item of data) {
    let match = false;
    for (const property of properties) {
      const itemPropertyValue = (item as any)[property];

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (itemPropertyValue?.toLowerCase().includes(searchInput.toLowerCase())) {
        match = true;
        break;
      }
    }

    if (match) {
      filteredArray.push(item);
    }
  }

  return filteredArray;
};

export const FilterPreloadChecksDataTableSource = (
  searchInput: string,
  preloadChecksData: IPreloadChecksDataTable[]
): IPreloadChecksDataTable[] => {
  const properties = [
    'orderNumber',
    'transporter',
    'truck',
    'trailer',
    'product',
    'date',
    'status',
  ];

  return filterDataTableSource(
    searchInput,
    preloadChecksData,
    properties
  ) as IPreloadChecksDataTable[];
};

export const FilterAnnualVettingsDataTableSource = (
  searchInput: string,
  annualVettingsData: IAnnualVettingsDataTable[]
): IAnnualVettingsDataTable[] => {
  const properties = ['transporter', 'truck', 'trailer', 'product', 'date', 'status'];

  return filterDataTableSource(searchInput, annualVettingsData, properties);
};

// Emiting A call api to search for managers when user opens manager select
export const handleSelectManagersSearch = (
  inputValue: string,
  params: { affiliate?: string; accessToken?: string | null },
  callback: (options: IOptions[]) => void
): void => {
  void searchManagers(inputValue, params.accessToken).then((searchedManagers) => {
    callback(searchedManagers);
  });
};

export const searchManagers = async (inputValue: string, accessToken?: string | null): Promise<IOptions[]> => {
  return await searchManagersList(inputValue, accessToken).then((managersList) => {
    return managersList.map((manager) => {
      return { value: manager.id, label: manager.name, name: manager.mail };
    });
  });
};

export const GetPreloadChecksDataTable = (
  preloadCheckList: IPreloadCheck[]
): IPreloadChecksDataTable[] => {
  const preloadChecksDataTable: IPreloadChecksDataTable[] = preloadCheckList.map((preloadCheck) => {
    return {
      id: preloadCheck.id,
      orderNumber: getPropertyAsString(preloadCheck, 'orders', 'order_number', false, 2),
      transporter: preloadCheck.transporter,
      truck: preloadCheck.truck.truck_number,
      trailer: preloadCheck.trailers.length.toString(),
      product: getPropertyAsString(preloadCheck, 'products', 'description', false, 2),
      agent:
        preloadCheck.agent !== undefined
          ? `${preloadCheck.agent.name} ${preloadCheck.agent.last_name}`
          : '-',
      date: moment(preloadCheck.created_at).format('YYYY/MM/DD'),
      status:
        (preloadCheck.status === PreloadCheckStatus.APPROVED ||
          preloadCheck.status === PreloadCheckStatus.REJECTED) &&
        (preloadCheck.security_check_status === PreloadCheckStatus.APPROVED ||
          preloadCheck.security_check_status === PreloadCheckStatus.REJECTED)
          ? preloadCheck.security_check_status
          : preloadCheck.status,
    };
  });

  return preloadChecksDataTable;
};

/**
 * Extracts and formats a specific property from an array of objects within a given property of an object.
 *
 * @param propertyObject - The object containing property that holds the array to be processed.
 * @param propertyKey - The key of the property that contains the array of objects (e.g., 'orders' or 'products').
 * @param formatKey - The key of the property within each object to extract and format (e.g., 'order_number' or 'description').
 * @param inline - If true, the items will be joined with a comma (', ') instead of a line break ('<br/>').
 * @param limit - The maximum number of items to include. If null, the full array will be used without slicing.
 * 
 * @returns A formatted string of extracted values, with an ellipsis ('...') appended if the original array length exceeds the limit (only if limit is not null).
 */
export const getPropertyAsString = (
  propertyObject: any,
  propertyKey: string,
  formatKey: string,
  inline: boolean = true,
  limit: number | null = null,
): string => {
  const items = propertyObject[propertyKey];
  if (items !== undefined && Array.isArray(items)) {
    const formattedItems = (limit !== null ? items.slice(0, limit) : items)
      .map((item: any) => item[formatKey])
      .join(inline ? ', ' : '<br/>');

    return formattedItems + (limit !== null && items.length > limit ? '...' : '');
  }
  return '';
};

/**
 * Generates a list of React elements based on a specified property of an object.
 * This function is generic and can handle different properties.
 *
 * @param propertyObject - The object containing various properties, from which the specified property will be extracted.
 * @param propertyKey - The key of the property in the object that you want to convert to elements.
 * @param valueExtractor - A function that defines how to extract a string representation from each item of the specified property.
 * 
 * @returns A JSX.Element
 */
export const getPropertyAsElements = <T, K extends keyof T>(
  propertyObject: T[],
  propertyKey: K,
  valueExtractor?: (item: T[K]) => string
): React.JSX.Element => {

  // Default value for valueExtractor if not provided
  const extractValue = valueExtractor ?? ((item) => (item as any).toString()); 

  return (
    <>
      {propertyObject.map((item, index) => (
        <span key={index}>
          {extractValue(item[propertyKey])}
        </span>
      ))}
    </>
  );
};

export const GetAnnualVettingsDataTable = (
  preloadCheckList: IAnnualVetting[]
): IAnnualVettingsDataTable[] => {
  const annualVettingsDataTable: IAnnualVettingsDataTable[] = preloadCheckList.map(
    (preloadCheck) => {
      return {
        id: preloadCheck.id,
        transporter: preloadCheck.transporter,
        truck: preloadCheck.truck.truck_number,
        trailer: preloadCheck.trailers.length.toString(),
        product: getPropertyAsString(preloadCheck, 'products', 'description'),
        agent:
          preloadCheck.agent !== undefined
            ? `${preloadCheck.agent.name} ${preloadCheck.agent.last_name}`
            : '-',
        date: moment(preloadCheck.created_at).format('YYYY/MM/DD'),
        status: preloadCheck.security_check_status,
      };
    }
  );

  return annualVettingsDataTable;
};

export const DisplayCards = (cards: ICard[], translate: any): React.JSX.Element => {
  return (
    <div className="cards-container">
      {cards.map((cardData, index) => {
        return (
          <div key={`card-${index}`} className="card">
            <h3>{cardData.title}</h3>
            <div className="card-description">
              {cardData.card.map((item, index) => {
                return (
                  <div key={`card-item-${index}`} className="card-description__item">
                    <p key={`card-item-elem-${index}`} className="label">
                      {translate(item.label)}
                    </p>
                    {item.link != null ? (
                      <a href={item.link} className="value">
                        {typeof item.value === 'string' ? item.value.length === 0 ? '-' : item.value : item.value}
                      </a>
                    ) : (
                      <p className="value">
                        {
                          // Check if item.value is a string and either:
                          typeof item.value === 'string' && 
                          (
                            // 1. The string is empty or contains only whitespace
                            item.value.trim().length === 0 || 
                            
                            // 2. The string is 'Invalid date'
                            item.value === 'Invalid date' || 
                            
                            // 3. The string is completely empty (edge case check)
                            item.value.length === 0
                          ) ||

                          // 4. item.value is null or undefined
                          item.value == null ||

                          // 5. item.value is an empty array
                          (Array.isArray(item.value) && item.value.length === 0) ||

                          // 6. item.value is an empty object (non-null)
                          (typeof item.value === 'object' && item.value !== null && Object.keys(item.value).length === 0)
                          ? '-'  // Return '-' if any of the above conditions are true
                          : item.value  // Otherwise, return the original value
                        }
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
// Emiting A Call Api To Search For Products When User Opens Filter Products Modal
export const FetchFiltersProducts = (
  offset: string,
  callback: (options: IOptionsData) => void,
  searchQuery?: string
): void => {
  void FetchFiltersProductsListMethod(offset, searchQuery).then((productsList) => {
    callback(productsList);
  });
};

export const FetchFiltersProductsListMethod = async (
  offset: string,
  searchQuery?: string
): Promise<IOptionsData> => {
  return await FetchFiltersProductsList(offset, searchQuery).then((data) => {
    const options = data.data.map((product) => {
      return { value: product.id, label: product.description };
    });

    return {
      pagination: data.pagination,
      options,
    };
  });
};

// Emiting A Call Api To Search For Transporters When User Opens Filter Transporters Modal
export const FetchFiltersTransporters = (
  offset: string,
  callback: (options: IOptionsData) => void,
  searchQuery?: string
): void => {
  void FetchFiltersTransportersListMethod(offset, searchQuery).then((productsList) => {
    callback(productsList);
  });
};

export const FetchFiltersTransportersListMethod = async (
  offset: string,
  searchQuery?: string
): Promise<IOptionsData> => {
  const data = await FetchFiltersTransportersList(offset, searchQuery);
  const options = data.data.map((transporter) => {
    return { value: transporter.id, label: transporter.name };
  });
  return {
    pagination: data.pagination,
    options,
  };
};

// Emiting A Call Api To Search For Transporters Contract's Transporters When User Opens Filter Transporters Modal
export const FetchFiltersTransportersContractsTransporters = (
  offset: string,
  callback: (options: IOptionsData) => void,
  searchQuery?: string
): void => {
  void FetchFiltersTransportersContractsTransportersListMethod(offset, searchQuery).then((productsList) => {
    callback(productsList);
  });
};

export const FetchFiltersTransportersContractsTransportersListMethod = async (
  offset: string,
  searchQuery?: string
): Promise<IOptionsData> => {
  const data = await FetchTransportersContractsFiltersTransportersList(offset, searchQuery);
  const options = data.data.map((transporter) => {
    return { value: transporter.id, label: transporter.name };
  });
  return {
    pagination: data.pagination,
    options,
  };
};

// Emiting A Call Api To Search For Trailer's Transporters When User Opens Filter Transporters Modal
export const FetchFiltersTrailersTransporters = (
  offset: string,
  callback: (options: IOptionsData) => void,
  searchQuery?: string
): void => {
  void FetchFiltersTrailersTransportersListMethod(offset, searchQuery).then((productsList) => {
    callback(productsList);
  });
};

export const FetchFiltersTrailersTransportersListMethod = async (
  offset: string,
  searchQuery?: string
): Promise<IOptionsData> => {
  const data = await FetchTrailersFiltersTransportersList(offset, searchQuery);
  const options = data.data.map((transporter) => {
    return { value: transporter.id, label: transporter.name };
  });
  return {
    pagination: data.pagination,
    options,
  };
};

// Emiting A Call Api To Search For Trailer's Products When User Opens Filter Products Modal
export const FetchFiltersTrailersProducts = (
  offset: string,
  callback: (options: IOptionsData) => void,
  searchQuery?: string
): void => {
  void FetchFiltersTrailersProductsListMethod(offset, searchQuery).then((productsList) => {
    callback(productsList);
  });
};

export const FetchFiltersTrailersProductsListMethod = async (
  offset: string,
  searchQuery?: string
): Promise<IOptionsData> => {
  const data = await FetchProductsList(offset, searchQuery);
  const options = data.data.map((product) => {
    return { value: product.id, label: product.description };
  });
  return {
    pagination: data.pagination,
    options,
  };
};

// Emiting A Call Api To Search For Driver's Transporters When User Opens Filter Transporters Modal
export const FetchFiltersDriversTransporters = (
  offset: string,
  callback: (options: IOptionsData) => void,
  searchQuery?: string
): void => {
  void FetchFiltersDriversTransportersListMethod(offset, searchQuery).then((transportersList) => {
    callback(transportersList);
  });
};

export const FetchFiltersDriversTransportersListMethod = async (
  offset: string,
  searchQuery?: string
): Promise<IOptionsData> => {
  const data = await FetchDriversFiltersTransportersList(offset, searchQuery);
  const options = data.data.map((transporter) => {
    return { value: transporter.id, label: transporter.name };
  });
  return {
    pagination: data.pagination,
    options,
  };
};

// Emiting A Call Api To Search For Truck's Transporters When User Opens Filter Transporters Modal
export const FetchFiltersTrucksTransporters = (
  offset: string,
  callback: (options: IOptionsData) => void,
  searchQuery?: string
): void => {
  void FetchFiltersTrucksTransportersListMethod(offset, searchQuery).then((transportersList) => {
    callback(transportersList);
  });
};

export const FetchFiltersTrucksTransportersListMethod = async (
  offset: string,
  searchQuery?: string
): Promise<IOptionsData> => {
  const data = await FetchTrucksFiltersTransportersList(offset, searchQuery);
  const options = data.data.map((transporter) => {
    return { value: transporter.id, label: transporter.name };
  });
  return {
    pagination: data.pagination,
    options,
  };
};

// Emiting A Call Api To Search For Agents When User Opens Filter Agents Modal
export const FetchFiltersAgents = (
  offset: string,
  callback: (options: IOptionsData) => void,
  searchQuery?: string
): void => {
  void FetchFiltersAgentsListMethod(offset, searchQuery).then((productsList) => {
    callback(productsList);
  });
};

export const FetchFiltersAgentsListMethod = async (
  offset: string,
  searchQuery?: string
): Promise<IOptionsData> => {
  return await FetchFiltersAgentsList(offset, searchQuery).then((data) => {
    const options = data.data.map((agent) => {
      return { value: agent.id, label: agent.name + ' ' + agent.last_name };
    });

    return {
      pagination: data.pagination,
      options,
    };
  });
};

export const FiltersRouteParamsUrl = (
  locationPathname: string,
  filtersForm: IFormData[],
  tabName?: string,
  parentTab?: string | null,
  onlyOneStatusAllowed?: boolean
): string => {
  const statusIds = GetFilterIds(filtersForm, 'status');
  const transporterIds = GetFilterIds(filtersForm, 'transporter');
  const productIds = GetFilterIds(filtersForm, 'product');
  const agentIds = GetFilterIds(filtersForm, 'agent');
  const dateValue = filtersForm.find((filterItem) => filterItem.name === 'date')?.value;

  let queyParams = '';

  if (tabName !== undefined) {
    queyParams += `${queyParams.length > 0 ? '&' : '?'}activeTab=${tabName}`;
  }

  if (statusIds !== undefined && statusIds.length > 0) {
    queyParams += `${queyParams.length > 0 ? '&' : '?'}statusFilters=${statusIds.join(',')}`;
  }

  if (transporterIds !== undefined && transporterIds.length > 0) {
    queyParams += `${queyParams.length > 0 ? '&' : '?'}transporterFilters=${transporterIds.join(
      ','
    )}`;
  }

  if (productIds !== undefined && productIds.length > 0) {
    queyParams += `${queyParams.length > 0 ? '&' : '?'}productFilters=${productIds.join(',')}`;
  }

  if (agentIds !== undefined && agentIds.length > 0) {
    queyParams += `${queyParams.length > 0 ? '&' : '?'}agentFilters=${agentIds.join(',')}`;
  }
  if (dateValue !== undefined && dateValue !== null) {
    queyParams += `${queyParams.length > 0 ? '&' : '?'}dateFilters=${dateValue}`;
  }

  if (parentTab !== undefined && parentTab !== null) {
    queyParams += `${queyParams.length > 0 ? '&' : '?'}parentTab=${parentTab}`;
  }

  if (onlyOneStatusAllowed !== undefined && onlyOneStatusAllowed) {
    queyParams += `${
      queyParams.length > 0 ? '&' : '?'
    }onlyOneStatusAllowed=${onlyOneStatusAllowed.toString()}`;
  }

  return `${locationPathname}${queyParams}`;
};

const GetFilterIds = (newFilters: IFormData[], filterName: string): string[] | undefined => {
  const filter = newFilters.find((filterItem) => filterItem.name === filterName);
  if (filter == null || filter.optionsData?.options == null) return undefined;

  const checkedOptions = filter.optionsData.options.filter((option) => option.checked);
  return checkedOptions.length > 0 ? checkedOptions.map((option) => option.value) : undefined;
};

export const InitFiltersData = (
  PreloadCheckFilters: IFormData[],
  FiltersTransportersList?: IOptionsData,
  FiltersProductsList?: IOptionsData,
  FiltersAgentsList?: IOptionsData,
  statusFilters?: string[],
  transportersFilters?: string[],
  productsFilters?: string[],
  agentsFilters?: string[],
  dateFilters?: string,
  onlyOneStatusAllowed?: boolean
): IFormData[] => {
  const FiltersData = PreloadCheckFilters.map((filter) => {
    if (filter.name === 'transporter' && FiltersTransportersList !== undefined) {
      return updateTransporterFilter(FiltersTransportersList, filter, transportersFilters);
    } else if (filter.name === 'product' && FiltersProductsList !== undefined) {
      return updateProductFilter(FiltersProductsList, filter, productsFilters);
    } else if (filter.name === 'agent' && FiltersAgentsList !== undefined) {
      return updateAgentFilter(FiltersAgentsList, filter, agentsFilters);
    } else if (filter.name === 'status') {
      return updateStatusFilter(PreloadCheckFilters, filter, statusFilters, onlyOneStatusAllowed);
    } else if (filter.name === 'date') {
      filter.value = dateFilters !== undefined ? dateFilters : undefined;
      return filter;
    } else {
      return filter;
    }
  });

  return FiltersData;
};

const updateStatusFilter = (
  PreloadCheckFilters: IFormData[],
  filterParam: IFormData,
  statusFilters?: string[],
  onlyOneStatusAllowed?: boolean
): IFormData => {
  const Filter = filterParam;
  Filter.optionsData = PreloadCheckFilters[0].optionsData as IOptionsData;
  if (onlyOneStatusAllowed !== undefined && onlyOneStatusAllowed && statusFilters !== undefined) {
    Filter.disabled = true;
    Filter.optionsData.options = Filter.optionsData.options.filter(
      (option) => option.value === statusFilters[0]
    );
  }
  Filter.optionsData.options = Filter.optionsData.options.map((option) => {
    const found = statusFilters?.find((id: string) => id === option.value);

    return {
      ...option,
      checked: found !== undefined, // Set checked to true if found in ids
    };
  });

  return Filter;
};

const updateTransporterFilter = (
  FiltersTransportersList: IOptionsData,
  filterParam: IFormData,
  transporterFilters?: string[]
): IFormData => {
  const Filter = filterParam;

  Filter.optionsData = FiltersTransportersList;
  Filter.optionsData.options = Filter.optionsData.options.map((option) => {
    const found = transporterFilters?.find((id: string) => id === option.value);

    return {
      ...option,
      checked: found !== undefined, // Set checked to true if found in ids
    };
  });

  return Filter;
};

const updateProductFilter = (
  FiltersProductsList: IOptionsData,
  filterParam: IFormData,
  productsFilters?: string[]
): IFormData => {
  const Filter = filterParam;

  Filter.optionsData = FiltersProductsList;
  Filter.optionsData.options = Filter.optionsData.options.map((option) => {
    const found = productsFilters?.find((id: string) => id === option.value);

    return {
      ...option,
      checked: found !== undefined, // Set checked to true if found in ids
    };
  });

  return Filter;
};

const updateAgentFilter = (
  FiltersAgentsList: IOptionsData,
  filterParam: IFormData,
  agentsFilters?: string[]
): IFormData => {
  const Filter = filterParam;

  Filter.optionsData = FiltersAgentsList;
  Filter.optionsData.options = Filter.optionsData.options.map((option) => {
    const found = agentsFilters?.find((id: string) => id === option.value);

    return {
      ...option,
      checked: found !== undefined, // Set checked to true if found in ids
    };
  });

  return Filter;
};
