import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Mock from '../../constants/Mock';
import {  IFormData, IOptions } from '../../interfaces/Form-Modal.interface';
import { IDriversDataTable } from '../../interfaces/Table.interface';
import { getDriversList } from '../../services/Driver.service';
import ButtonComponent from '../../shared/components/button/Button';
import Stats from '../../shared/components/stats/Stats';
import IconExport from './../../assets/icons/icon-export.svg';
import { useTranslation } from 'react-i18next';
import { IDriver } from '../../interfaces/Driver.interface';
import moment from 'moment';
import { Snackbar } from '@mui/material';
import Loader from '../../shared/components/Loader/Loader';
import { 
  FetchFiltersDriversTransporters,
  FetchFiltersDriversTransportersListMethod
} from '../../services/Helper.service';
import { useSnackbar } from '../../services/SnackbarUtils';
import { IPagination } from '../../interfaces/Pagination.interface';
import { ExportData } from '../../services/Export.service';
import { FieldEnums } from '../../enums/Field.enum';

interface IDriversProps {
  Transporters: IOptions[];
}

const Drivers = (props: IDriversProps): JSX.Element => {
  const [driversListPagination, setDriversListPagination] = useState<IPagination>();
  const [driversDataTable, setDriversDataTable] = useState<IDriversDataTable[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [nextPageLink, setNextPageLink] = useState<string | null>(null);
  const [isFetchingMore, setIsFetchingMore] = useState<boolean>(false);
  const { t } = useTranslation('common');
  const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>();
  const { DriversListColumns } = Mock();
  const { snackBarOpen, snackbarMessage, snackBarType, showSnackbar, handleSnackBarClose } =
    useSnackbar();
  const [exportUrl, setExportUrl] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const transporterFilters = searchParams
    .get('transporterFilters')
    ?.split(',')
    .map((item) => item.trim());
  const [DriversFitlers, setDriversFitlers] = useState<IFormData[]>([
    {
      componentType: FieldEnums.CUSTOMSELECT,
      label: 'transporter',
      type: 'text',
      placeholder: 'select_a_transporter',
      name: 'transporter',
      optionsData: undefined,
      handleFetchMoreData: FetchFiltersDriversTransporters,
    },
  ]);

  /* METHOD EMITTING API CALLS */
  const fetchDriversList = (offset: number, nextLink?: string): void => {
    setIsRequestInProgress(true);

    getDriversList(10, offset, nextLink, transporterFilters, searchValue)
      .then((driversData) => {
        setIsRequestInProgress(false);
        setIsFetchingMore(false);

        if (nextLink !== null) {
          setDriversDataTable((prevData) => [
            ...prevData,
            ...getDriversDataTable(driversData.data),
          ]);
        } else {
          setDriversDataTable(getDriversDataTable(driversData.data));
          setDriversListPagination(driversData.pagination);
        }

        // Store the next page link for infinite scroll
        setNextPageLink(driversData.nextLink ?? null);
        setExportUrl(driversData.exportUrl);
      })
      .catch((error) => {
        setIsRequestInProgress(false);
        setIsFetchingMore(false);
        showSnackbar(error.message, 'error');
      });
  };

  const handleTablePaginationChange = (tablePageIndex: number): void => {
    const tablePageSize = 10;
    const offset = tablePageIndex * tablePageSize;
    fetchDriversList(offset);
  };

  /* OTHER METHODS */
  const getDriversDataTable = (driversData: IDriver[]): IDriversDataTable[] => {
    return driversData.map((driver) => {
      return {
        id: driver.id,
        surname: driver.last_name,
        name: driver.name,
        transporter: driver.transporter,
        affiliate: driver.affiliate,
        licenceNumber: driver.driver_licence,
        licenceExpirationDate: driver.exp_licence === null ? '-' : moment(driver.exp_licence).format('MM/YYYY'),
        checksNumber: driver.nbChecks,
      };
    });
  };

  const handleTableRowClicked = (driverId: string): void => {
    navigate(`/drivers/details/${driverId}`);
  };

  const updateFiltersData = (newFilters: IFormData[]): void => {
    setDriversFitlers(newFilters);
    const driverIds = newFilters
      .find((filter) => filter.name === 'transporter')
      ?.optionsData?.options.filter((option) => option.checked)
      .map((option) => option.value);

    let queryString = '';
    if (driverIds !== undefined) {
      queryString = `?transporterFilters=${driverIds.join(',')}`;

      if (driverIds.length > 0) {
        navigate('/drivers' + queryString);
      } else {
        navigate('/drivers');
      }
    }
  };

  /* Export Method */
  const DriversExport = (): any => {
    if (exportUrl !== undefined) {
      setIsRequestInProgress(true);

      ExportData(exportUrl)
        .then(() => {
          setIsRequestInProgress(false);
        })
        .catch((error) => {
          setIsRequestInProgress(false);
          showSnackbar(error.message, 'error');
        });
    }
  };

  const SetFiltersData = async (): Promise<void> => {
    const FiltersTransportersOptionsData = await FetchFiltersDriversTransportersListMethod('0');

    const Fitlers = DriversFitlers.map((filter) => {
      filter.optionsData = FiltersTransportersOptionsData;
      filter.optionsData.options = filter.optionsData.options.map((option) => {
        const found = transporterFilters?.find((id: string) => id === option.value);

        return {
          ...option,
          checked: found !== undefined, // Set checked to true if found in ids
        };
      });
      return filter;
    });

    setDriversFitlers(Fitlers);
  };

  const setDriversHeaderButtons = (): React.JSX.Element => {
    return (
      <>
        {exportUrl !== undefined && (
          <ButtonComponent
            iconUrl={IconExport}
            iconAlt="icon-export"
            label={t('export')}
            color="secondary btn-transparent btn-outlined"
            onClick={DriversExport}
          />
        )}

      </>
    );
  };

  const handleSubmitSearch = (value: string): void => {
    setSearchValue(value);
  };

  const searchHandleClear = (): void => {
    setSearchValue('');
  };

  const filteredDrivers = useMemo(() => {
    if (searchValue === undefined || searchValue.trim() === '' || searchValue === null) return driversDataTable;

    return driversDataTable.filter((driver) =>
      Object.values(driver).some((value) =>
        typeof value === 'string' && value.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue, driversDataTable]);

  const handleLoadMore = (): void => {
    if (nextPageLink === null || isFetchingMore) return;
    setIsFetchingMore(true);
    fetchDriversList(0, nextPageLink);
  };

  useEffect(() => {
    setDriversDataTable([]);
    setNextPageLink(null);
    void SetFiltersData();
    fetchDriversList(0);
  }, [props, location]);

  return (
    <div>
      {isRequestInProgress === true && <Loader />}

      <Stats
        searchValue={searchValue}
        onSearchSubmit={handleSubmitSearch}
        onSearchClear={searchHandleClear}
        headerButtons={setDriversHeaderButtons()}
        dataTable={filteredDrivers}
        columnsTable={DriversListColumns}
        emptyMsgTable={isRequestInProgress === false ? t('no_drivers_to_display') : ''}
        onTableRowClicked={handleTableRowClicked}
        filtersData={DriversFitlers}
        updateFiltersData={updateFiltersData}
        handleTablePaginationChange={handleTablePaginationChange}
        tableTotalPages={driversListPagination !== undefined ? driversListPagination.totalPages : 0}
        searchPlaceholder={t('driver_search_placeholder')}
        nextPageLink={nextPageLink}
        handleLoadMore={handleLoadMore}
      />

      <Snackbar
        className={`oryx-snackbar ${snackBarType}`}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarOpen}
        message={snackbarMessage}
        autoHideDuration={3000}
        onClose={handleSnackBarClose}
      />
    </div>
  );
};

export default Drivers;
